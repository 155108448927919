import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import Report from 'components/Report';
import reportService from 'services/reportService';

const RptGetSalesTotal = () => {


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Report
                getData={reportService.getSalesByTotal}
                columns={[
                    {
                        "field": "x",
                        "headerText": "المادة",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "y",
                        "headerText": "المبيعات",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "z",
                        "headerText": "الكلفة",
                        "width": 90,
                        "textAlign": "right"
                    },
                ]} />
            <Footer />
        </DashboardLayout>
    );
}

export default RptGetSalesTotal;
