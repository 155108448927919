import HttpService from '../utility/httpService';
class preferencesService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_Preferences/GetPreferences");
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Preferences/UpdatePreferences", data);
        return response;
    }
}

export default new preferencesService();