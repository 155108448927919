import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import withAuth from 'functions/withAuth';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const BalanceChargeList = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row className='mb-2'>
                <Col md={2}>
                    <Form.Label>من تاريخ</Form.Label>
                    <Form.Control onChange={e => setFromDate(e.target.value)} type="date" />
                </Col>
                <Col md={2}>
                    <Form.Label>إلى تاريخ</Form.Label>
                    <Form.Control onChange={e => setToDate(e.target.value)} type="date" />
                </Col>
            </Row>
            <MyGridView
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_BalanceCharge/BalanceChargeList?fromDate=${fromDate}&toDate=${toDate}`}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "senderUserName",
                        "headerText": "المرسل",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "receiverUserName",
                        "headerText": "المستفيد",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "transDate",
                        "headerText": "التاريخ",
                        "width": 90,
                        "format": "dd/MM/yyyy",
                        "textAlign": "right"
                    },
                    {
                        "field": "amount",
                        "headerText": "القيمة",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "oldBalance",
                        "headerText": "الرصيد قبل",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "newBalance",
                        "headerText": "الرصيد بعد",
                        "width": 90,
                        "textAlign": "right"
                    },
                ]}
                allowFiltering={true}
                actionButtons={[]}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(BalanceChargeList);