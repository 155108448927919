import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import paymentMethodService from 'services/paymentMethodService';

const PaymentMethodDetails = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [img, setImg] = useState("");
    const [imgFile, setImgFile] = useState(null);
    const [walletNumber, setWalletNumber] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const isEditMode = Number(id);

    function validateOffer(newPaymentMethod) {
        var errorFileds = [];
        if (String(newPaymentMethod.name).trim() === "") {
            errorFileds.push("الاسم");
        }
        if (String(newPaymentMethod.walletNumber).trim() === "") {
            errorFileds.push("رقم المحفظة");
        }
        if (errorFileds.length > 0) {
            let errorFiledsString = errorFileds.join();
            toast.error("يرجى ملئ الحقول : " + errorFiledsString)
            return false
        }
        return true
    }

    function handleSubmit() {
        setWaiting(true);
        var newPaymentMethod = {
            "id": id,
            "name": name,
            "description": desc,
            "imageUrl": img,
            "imageFile": imgFile,
            "walletNumber": walletNumber
        }
        if (!validateOffer(newPaymentMethod)) {
            setWaiting(false);
            return false;
        }
        if (isEditMode) {
            paymentMethodService.update(newPaymentMethod).then(res => {
                setWaiting(false);
            })
        } else {
            paymentMethodService.create(newPaymentMethod).then(res => {
                navigate("/paymentMethods");
            })
        }
    }

    function handleImgChange(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImgFile(reader.result);
            setImg(reader.result)
        };
        reader.onerror = error => {
            console.error("Error: ", error);
        };
    }

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (isEditMode) {
                let paymentMethod = await paymentMethodService.getById(id);
                if (isMounted) {
                    setName(paymentMethod.name);
                    setDesc(paymentMethod.description);
                    setImg(paymentMethod.imageUrl);
                    setWalletNumber(paymentMethod.walletNumber);
                }
            }
            setIsDataLoaded(true);
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id]);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row>
                <Col md={2}>
                    <Form.Label>الاسم</Form.Label>
                    <Form.Control value={name} onChange={e => setName(e.target.value)}></Form.Control>
                </Col>
                <Col md={2}>
                    <Form.Label>رقم المحفظة</Form.Label>
                    <Form.Control value={walletNumber} onChange={e => setWalletNumber(e.target.value)}></Form.Control>
                </Col>
                <Col md={2}>
                    <Form.Label>التفاصيل</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                    ></Form.Control>
                </Col>
                <Col md={2}>
                    <div style={{ width: "250px", padding: "5px" }}>
                        <img style={{ width: "100px" }} src={img} alt="Nothing to present" />
                        <MDInput accept="image/*" type="file" size="small" fullWidth onChange={e => handleImgChange(e)} />
                    </div>
                </Col>
            </Row>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px">
                    {waiting ? <CircularProgress color="success" /> : <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}>
                        حفظ
                    </MDButton>}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/paymentMethods")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default PaymentMethodDetails;
