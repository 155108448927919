import HttpService from '../utility/httpService';
class poService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_Supplier/GetAllSuppliers");
        return response;
    }

    async getLookups() {
        const response = await HttpService.get("/Admin/CMS_Lookups/SuppliersLookup");
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_Supplier/AddSupplier", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Supplier/UpdateSupplier", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_Supplier/DeletesSupplier?id=" + id);
        return response;
    }
}

export default new poService();