import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import withAuth from 'functions/withAuth';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import './index.css'

const TransactionsList = (props) => {
    const { id } = useParams();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    console.log('selectedOptions : ', selectedOptions.join());

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const handleOptionClick = (option) => {
        const isChecked = selectedOptions.includes(option);
        if (isChecked) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row className='mb-2'>
                <Col md={2}>
                    <Form.Label>من تاريخ</Form.Label>
                    <Form.Control onChange={e => setFromDate(new Date(e.target.value).toISOString())} type="date" />
                </Col>
                <Col md={2}>
                    <Form.Label>إلى تاريخ</Form.Label>
                    <Form.Control onChange={e => setToDate(new Date(e.target.value).toISOString())} type="date" />
                </Col>
                <Col className='align-self-end'>
                    <Row className='justify-content-start'>
                        <Col md={2} className='options' style={{ backgroundColor: 'mediumseagreen', }} onClick={() => handleOptionClick('1')}>
                            <Form.Check
                                type="checkbox"
                                label="شحن رصيد"
                                checked={selectedOptions.includes('1')}
                                onChange={() => handleCheckboxChange('1')}
                            />
                        </Col>
                        <Col md={2} className='options' style={{ backgroundColor: 'orange', }} onClick={() => handleOptionClick('2')}>
                            <Form.Check
                                type="checkbox"
                                label="سحب رصيد"
                                checked={selectedOptions.includes('2')}
                                onChange={() => handleCheckboxChange('2')}
                            />
                        </Col>
                        <Col md={2} className='options' style={{ backgroundColor: 'skyBlue', }} onClick={() => handleOptionClick('3')}>
                            <Form.Check
                                type="checkbox"
                                label="مبيعات"
                                checked={selectedOptions.includes('3')}
                                onChange={() => handleCheckboxChange('3')}
                            />
                        </Col>
                        <Col md={2} className='options' style={{ backgroundColor: 'indianred', }} onClick={() => handleOptionClick('4')}>
                            <Form.Check
                                type="checkbox"
                                label="مرتجعات"
                                checked={selectedOptions.includes('4')}
                                onChange={() => handleCheckboxChange('4')}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <MyGridView
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_Users/UserTransactionsById?userId=${id}&fromDate=${fromDate}&toDate=${toDate}&selectedTrans=${selectedOptions.join()}`}
                columns={[
                    {
                        "field": "transTypeName",
                        "headerText": "نوع الحركة",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "transDate",
                        "headerText": "التاريخ",
                        "width": 90,
                        "format": "dd/MM/yyyy",
                        "textAlign": "right"
                    },
                    {
                        "field": "oldBalance",
                        "headerText": "الرصيد قبل",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "newBalance",
                        "headerText": "الرصيد بعد",
                        "width": 90,
                        "textAlign": "right"
                    },
                ]}
                allowFiltering={true}
                actionButtons={[]}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(TransactionsList);