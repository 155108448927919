import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { CircularProgress } from "@mui/material";
import auth from "services/auth";
import { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
// import authService from "../../../services/auth"

function Basic() {
  let navigate = useNavigate();

  const [wait, setWait] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");


  const submit = () => {
    setWait(true);
    auth.login(userName, password, rememberMe).then(result => {
      setWait(false);
      if (result) {
        navigate("/dashboard");
      }
    })
  }

  useEffect(() => {
    const token = auth.getToken();
    if (token) {
      auth.validateToken(token).then(isValid => {
        if (isValid) {
          navigate('/dashboard');
        }

      });
    }
  }, []);

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          </Grid>
        </MDBox>
        <Form>
          <Col className="m-4">
            <Form.Text>User Name</Form.Text>
            <Form.Control value={userName} onChange={e => setUserName(e.target.value)} />
            <Form.Text>Password</Form.Text>
            <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} />
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={() => setRememberMe(!rememberMe)}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {wait ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="success" />
                </div>
                :
                <Row>
                  <Button
                    variant="primary"
                    type="submit"
                    fullWidth
                    onClick={submit}>
                    sign in
                  </Button>
                </Row>}
            </MDBox>
          </Col>
        </Form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
