import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import withAuth from 'functions/withAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import itemService from 'services/itemService';
import poService from 'services/poService';

const ItemsList = () => {
    let navigate = useNavigate();
    const gridViewRef = React.useRef(null);

    const refreshGridView = () => {
        gridViewRef.current.refresh();
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyGridView
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_Items/GetAllItems`}
                ref={gridViewRef}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "itemName",
                        "headerText": "المنتج",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "itemDesc",
                        "headerText": "التفاصيل",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "catName",
                        "headerText": "العائلة",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "price",
                        "headerText": "السعر",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "avgCost",
                        "headerText": "متوسط التكلفة",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "isActive",
                        "headerText": "فعال",
                        "width": 90,
                        "textAlign": "right",
                        'displayAsCheckBox': "true",
                        'editType': "booleanedit",
                        'type': "boolean",
                        'clipMode': "EllipsisWithTooltip",
                        'filterTemplate': () => false
                    },
                ]}
                allowFiltering={true}
                actionButtons={[
                    {
                        "content": <Icon color='info'>edit</Icon>,
                        "action": (id) => {
                            return navigate("/items/" + id)
                        }
                    },
                    {
                        "content": <Icon color='error'>delete</Icon>,
                        "action": (id) => {
                            MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف!", () => {
                                itemService.delete(id).then(res => {
                                    refreshGridView();
                                });
                            })
                        }
                    },
                ]}
                addAction={(dd) => {
                    return navigate("/items/" + 0);
                }}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(ItemsList);
