import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import dynamicPagesService from 'services/dynamicPagesService';

const DynamicPageDetails = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [img, setImg] = useState("");
    const [imgFile, setImgFile] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const isEditMode = Number(id);
    var contentRef;

    function validateOffer(newDynamicPage) {
        var errorFileds = [];
        if (String(newDynamicPage.title).trim() === "") {
            errorFileds.push("العنوان");
        }
        if (String(newDynamicPage.content).trim() === "") {
            errorFileds.push("رقم المحتوى");
        }
        if (errorFileds.length > 0) {
            let errorFiledsString = errorFileds.join();
            toast.error("يرجى ملئ الحقول : " + errorFiledsString)
            return false
        }
        return true
    }

    function handleSubmit() {
        setWaiting(true);
        var newDynamicPage = {
            "id": id,
            "title": title,
            "imageFile": imgFile,
            "imageUrl": img,
            "content": contentRef.value
        }
        if (!validateOffer(newDynamicPage)) {
            setWaiting(false);
            return false;
        }
        if (isEditMode) {
            dynamicPagesService.update(newDynamicPage).then(res => {
                setWaiting(false);
            })
        } else {
            dynamicPagesService.create(newDynamicPage).then(res => {
                navigate("/dynamicPages");
            })
        }
    }

    function handleImgChange(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImgFile(reader.result);
            setImg(reader.result)
        };
        reader.onerror = error => {
            console.error("Error: ", error);
        };
    }

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (isEditMode) {
                let paymentMethod = await dynamicPagesService.getById(id);
                if (isMounted) {
                    setTitle(paymentMethod.title);
                    setContent(paymentMethod.content);
                    setImg(paymentMethod.imageUrl);
                }
            }
            setIsDataLoaded(true);
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, isEditMode]);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Col>
                <Row>
                    <Form.Label>العنوان</Form.Label>
                    <Form.Control value={title} onChange={e => setTitle(e.target.value)}></Form.Control>
                </Row>
                <Row>
                    <Form.Label>المحتوى</Form.Label>
                    <RichTextEditorComponent
                        ref={(richtexteditor) => { contentRef = richtexteditor; }}
                        value={content}
                        change={e => setContent(e.value)}
                        toolbarSettings={{
                            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                                'LowerCase', 'UpperCase', '|',
                                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                                'Outdent', 'Indent', '|',
                                'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                                'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
                        }}>
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                </Row>
                <Row>
                    <Form.Label>الصورة</Form.Label>
                    <div style={{ width: "250px", padding: "5px" }}>
                        <img style={{ width: "100px" }} src={img} alt="Nothing to present" />
                        <MDInput accept="image/*" type="file" size="small" fullWidth onChange={e => handleImgChange(e)} />
                    </div>
                </Row>
            </Col>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px">
                    {waiting ? <CircularProgress color="success" /> : <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}>
                        حفظ
                    </MDButton>}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/dynamicPages")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default DynamicPageDetails;
