class CustomerClassModel {
    constructor (id, className, isDeleted = false, itemsPrices = null, customerClasses = null) {
        this.id = id;
        this.className = className;
        this.isDeleted = isDeleted;
        this.itemsPrices = itemsPrices;
        this.customerClasses = customerClasses;
    }

    static fromJson = (json) => {
        return new CustomerClassModel(
            json.id,
            json.className,
            json.isDeleted,
            json.itemsPrices,
            json.customerClasses,
        );
    }

    toJson = () => {
        return {
            id: this.id,
            className: this.className,
            isDeleted: this.isDeleted,
            itemsPrices: this.itemsPrices,
            customerClasses: this.customerClasses,
        };
    }
}

export default CustomerClassModel;
