import HttpService from '../utility/httpService';
class agentService {

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_Agents/GetAgentById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_Agents/AddAgent", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Agents/UpdateAgent", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_Agents/DeleteAgent?id", id);
        return response;
    }
}

export default new agentService();