import { CircularProgress, Icon, IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import extraFieldsService from 'services/extraFieldsService';
import SearchList from 'components/SearchList';

const ExtraFiledsDetails = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [text, setText] = useState("");
    const [fieldType, setFieldType] = useState(0);
    const [options, setOptions] = useState(['']);
    const [waiting, setWaiting] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const isEditMode = Number(id);

    //-------------
    const handleAddControl = () => {
        setOptions(prevControls => [...prevControls, '']);
    };

    const handleRemoveControl = index => {
        setOptions(prevControls => {
            const updatedControls = [...prevControls];
            updatedControls.splice(index, 1);
            return updatedControls;
        });
    };

    const handleControlChange = (index, value) => {
        setOptions(prevControls => {
            const updatedControls = [...prevControls];
            updatedControls[index] = value;
            return updatedControls;
        });
    };
    //-------------

    function handleSubmit() {
        setWaiting(true);
        if (String(text).trim() === "") {
            toast.error("اسم الحقل مطلوب");
            setWaiting(false);
            return false;
        }
        const extraField = {
            "id": id,
            "fieldName": text,
            "fieldType": fieldType,
            "options": options.join(),
        }
        if (isEditMode) {
            extraFieldsService.update(extraField).then(res => {
                setWaiting(false);
            })
        } else {
            extraFieldsService.create(extraField).then(res => {
                if (res) {
                    navigate("/extraFields");
                } else {
                    setWaiting(false);
                }
            })
        }
    }

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (isEditMode) {
                let extraField = await extraFieldsService.getById(id);
                if (isMounted) {
                    setText(extraField.fieldName);
                    setFieldType(extraField.fieldType);
                    if (extraField.options) {
                        setOptions(extraField.options.split(","));
                    }
                }
            }
            console.log('AAASSSSS');
            setIsDataLoaded(true);
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, isEditMode]);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row>
                <Col md={2}>
                    <Form.Label>الحقل</Form.Label>
                    <Form.Control value={text} onChange={e => setText(e.target.value)}></Form.Control>
                </Col>
                <Col md={2}>
                    <Form.Label>نوع الحقل</Form.Label>
                    <SearchList
                        data={[{ id: 0, name: "مربع نص" }, { id: 1, name: "قائمة اختيار" }, { id: 2, name: "صورة" }]}
                        fiels={["name", "id"]}
                        take={10}
                        value={fieldType}
                        onChange={(id, name) => setFieldType(id)}
                    />
                </Col>
            </Row>
            {
                fieldType === 1
                    ?
                    <Row className='mt-3'>
                        <Col md={2}>
                            <Col>
                                <IconButton onClick={handleAddControl}>
                                    <Icon color='success' fontSize='large'>add_circle</Icon>
                                </IconButton>
                                <IconButton onClick={() => handleRemoveControl(options.length - 1)}>
                                    <Icon color='error' fontSize='large'>remove_circle</Icon>
                                </IconButton>
                            </Col>
                            {options.map((value, index) => (
                                <Row key={index} className='mt-1'>
                                    <Form.Control
                                        key={index}
                                        value={value}
                                        onChange={e => handleControlChange(index, e.target.value)}
                                    />
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    :
                    <></>
            }
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px">
                    {waiting ? <CircularProgress color="success" /> : <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}>
                        حفظ
                    </MDButton>}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/extraFields")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default ExtraFiledsDetails;
