import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import withAuth from 'functions/withAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import itemStockService from 'services/itemStockService';

const StockItemsList = () => {
    let navigate = useNavigate();
    const gridViewRef = React.useRef(null);

    const refreshGridView = () => {
        gridViewRef.current.refresh();
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyGridView
                ref={gridViewRef}
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_ItemStock/ItemStocksList`}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "name",
                        "headerText": "المنتج",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "availableQty",
                        "headerText": "الكمية المتوفرة",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "totalQty",
                        "headerText": "إجمالي الكمية",
                        "width": 90,
                        "textAlign": "right",
                    },
                    {
                        "field": "soldQty",
                        "headerText": "الكمية المباعة",
                        "width": 90,
                        "textAlign": "right",
                        'template': (rowData) => {
                            const result = rowData.totalQty - rowData.availableQty;
                            return <span>{result}</span>;
                        }
                    },

                ]}
                allowFiltering={true}
                actionButtons={[
                    {
                        "content": <Icon color='info'>edit</Icon>,
                        "action": (id) => {
                            return navigate("/stockItems/" + id);
                        }
                    },
                    {
                        "content": <Icon color='error'>delete</Icon>,
                        "action": (id) => {
                            MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف", () => {
                                itemStockService.delete(id).then(res => {
                                    refreshGridView();
                                })
                            })

                        }
                    },
                ]}
                addAction={() => {
                    return navigate("/stockItems/" + 0);
                }}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(StockItemsList);
