import HttpService from '../utility/httpService';
class ReportService {

    async getSalesByTotal(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesTotal?fromDate=${from}&toDate=${to}`);
        return response;
    }
    async getSalesByItem(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesByItem?fromDate=${from}&toDate=${to}`);
        return response;
    }
    async getSalesByDate(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesByDate?fromDate=${from}&toDate=${to}`);
        return response;
    }
    async getSalesByCustomer(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesByCustomer?fromDate=${from}&toDate=${to}`);
        return response;
    }
    async getSalesByCategory(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesByCategory?fromDate=${from}&toDate=${to}`);
        return response;
    }
    async getSalesByAgentDaily(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesByAgentDaily?fromDate=${from}&toDate=${to}`);
        return response;
    }
    async getSalesByAgent(from, to) {
        const response = await HttpService.get(`/Admin/CMS_Reports/GetSalesByAgent?fromDate=${from}&toDate=${to}`);
        return response;
    }

}

export default new ReportService();