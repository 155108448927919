import { CircularProgress, Switch } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import SearchList from 'components/SearchList';
import customerClassService from 'services/customerClassService';
import customerService from 'services/customerService';
import withAuth from 'functions/withAuth';
import ManageBalance from 'components/ManageBalance';
import auth from 'services/auth';

const CustomerDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [customerClassData, setCustomerClassData] = useState([]);
    const [customerClassId, setCustomerClassId] = useState(1);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [creditLimit, setCreditLimit] = useState(0);
    const [maxAllowedWithdrawDaily, setMaxAllowedWithdrawDaily] = useState(0);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [isExemptOfMaxSalesQty, setIsExemptOfMaxSalesQty] = useState(false);
    const [isExemptOfMaxSalesQtyInDay, setIsExemptOfMaxSalesQtyInDay] = useState(false);
    const [IsAllowCredit, setIsAllowCredit] = useState(false);

    const [waiting, setWaiting] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const isEditMode = Number(id);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const customerclasses = await customerClassService.getAll();
                if (isMounted) {
                    setCustomerClassData(customerclasses.map(e => { return { id: e.id, name: e.className } }))
                }
                if (isEditMode) {
                    const res = await customerService.getById(id)
                    if (isMounted) {
                        // Update state only if the component is still mounted
                        setFirstName(res.firstName);
                        setLastName(res.lastName);
                        setEmail(res.email);
                        setPassword(res.password);
                        setPhoneNumber(res.phoneNumber);
                        setIsActive(res.isActive);
                        setIsDataLoaded(true);
                        setCreditLimit(res.creditLimit);
                        setCustomerClassId(res.classId);
                        setMaxAllowedWithdrawDaily(res.maxAllowedWithdrawDaily);
                        setIsExemptOfMaxSalesQty(res.isExemptOfMaxSalesQty);
                        setIsExemptOfMaxSalesQtyInDay(res.isExemptOfMaxSalesQtyInDay);
                        setCurrentBalance(res.currentBalance);
                        setIsAllowCredit(res.IsAllowCredit);
                    } else {
                        setIsDataLoaded(true);
                    }
                } else {
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        return () => {
            // Cleanup function
            isMounted = false;
            // Cancel any ongoing asynchronous tasks or subscriptions here
            // ...
        };
    }, [isEditMode, id]);

    const validatePassword = (password) => {
        // Minimum length of 8 characters
        if (password.length < 8) {
            return 'Password must be at least 8 characters long.';
        }

        // At least 3 letters
        const letterRegex = /[a-zA-Z]/g;
        const letterMatches = password.match(letterRegex);
        if (!letterMatches || letterMatches.length < 3) {
            return 'Password must contain at least 3 letters.';
        }

        // At least 1 uppercase letter
        const uppercaseRegex = /[A-Z]/g;
        const uppercaseMatches = password.match(uppercaseRegex);
        if (!uppercaseMatches || uppercaseMatches.length < 1) {
            return 'Password must contain at least 1 uppercase letter.';
        }

        // At least 3 numeric characters
        const numericRegex = /[0-9]/g;
        const numericMatches = password.match(numericRegex);
        if (!numericMatches || numericMatches.length < 3) {
            return 'Password must contain at least 3 numeric characters.';
        }

        // At least 1 special character
        const specialRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
        const specialMatches = password.match(specialRegex);
        if (!specialMatches || specialMatches.length < 1) {
            return 'Password must contain at least 1 special character.';
        }

        // Password meets all criteria
        return null;
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPasswordError(validatePassword(newPassword));
    };

    const handleSubmit = () => {
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        setWaiting(true);

        const customerData = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phoneNumber": phoneNumber,
            "password": password,
            "classId": customerClassId,
            "creditLimit": creditLimit,
            "maxAllowedWithdrawDaily": maxAllowedWithdrawDaily,
            "isExemptOfMaxSalesQty": isExemptOfMaxSalesQty,
            "isExemptOfMaxSalesQtyInDay": isExemptOfMaxSalesQtyInDay,
            "isChangedPassword": false
        };

        if (isEditMode) {
            customerData.id = id;
            customerService.update(customerData).then(() => {
                setWaiting(false);
            });
        } else {
            customerData.password = password;
            customerService.create(customerData).then(res => {
                if (res) {
                    navigate('/customers');
                }
                setWaiting(false);
            });
        }
    };

    function activateAccount() {
        if (auth.activateAccount(id)) {
            setIsActive(true);
            toast.success("تم التفعيل بنجاح");
        } else {
            toast.error("حطأ في عملية تفعيل الحساب");
        }
    }
    function deActivateAccount() {
        if (auth.deActivateAccount(id)) {
            setIsActive(false);
            toast.success("تم إلغاء التفعيل بنجاح");
        } else {
            toast.error("حطأ في عملية إلغاء تفعيل الحساب");
        }
    }

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                isEditMode
                    ?
                    <ManageBalance
                        name={firstName + " " + lastName}
                        userId={id}
                    />
                    :
                    <></>
            }
            <table>
                <thead></thead>
                <tbody>
                    {
                        isEditMode
                            ?
                            <tr>
                                <td>
                                    <p className="control-label" style={{ textAlign: 'left', fontSize: '16px', fontWeight: 'bold', color: currentBalance < 0 ? 'red' : 'green' }}>
                                        الرصيد الحالي : {currentBalance}</p>
                                </td>
                            </tr>
                            :
                            <></>
                    }
                    <tr>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                الاسم الأول</label>
                        </td>
                        <td>
                            <MDInput value={firstName} size="small" fullWidth onChange={e => setFirstName(e.target.value)} />
                        </td>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                الاسم الثاني</label>
                        </td>
                        <td>
                            <MDInput value={lastName} size="small" fullWidth onChange={e => setLastName(e.target.value)} />
                        </td>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                التصنيف </label>
                        </td>
                        <td>
                            <SearchList
                                data={customerClassData}
                                take={10}
                                value={customerClassId}
                                onChange={(id, name) => {
                                    setCustomerClassId(id)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                البريد الإلكتروني</label>
                        </td>
                        <td>
                            <MDInput value={email} size="small" fullWidth onChange={e => setEmail(e.target.value)} />
                        </td>
                        {!isEditMode && (
                            <>
                                <td>
                                    <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                        كلمة المرور</label>
                                </td>
                                <td>
                                    <MDInput
                                        value={password}
                                        size="small"
                                        fullWidth
                                        onChange={handlePasswordChange}
                                    />
                                </td>
                            </>
                        )}
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                رقم الهاتف</label>
                        </td>
                        <td>
                            <MDInput value={phoneNumber} size="small" fullWidth onChange={e => setPhoneNumber(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                حد الدين</label>
                        </td>
                        <td>
                            <MDInput value={creditLimit} size="small" fullWidth onChange={e => setCreditLimit(e.target.value)} />
                        </td>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                اكثر حد للسحب</label>
                        </td>
                        <td>
                            <MDInput value={maxAllowedWithdrawDaily} size="small" fullWidth onChange={e => setMaxAllowedWithdrawDaily(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={() => { }}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    مسموح الدين</label>
                            </MDTypography>
                        </td>
                        <td>
                            <MDBox display="flex" alignItems="center" ml={-1}>
                                <Switch checked={IsAllowCredit} onChange={() => setIsAllowCredit(!IsAllowCredit)} />
                            </MDBox>
                        </td>
                        <td>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={() => { }}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    معفى من حد المنتجات</label>
                            </MDTypography>
                        </td>
                        <td>
                            <MDBox display="flex" alignItems="center" ml={-1}>
                                <Switch checked={isExemptOfMaxSalesQty} onChange={() => setIsExemptOfMaxSalesQty(!isExemptOfMaxSalesQty)} />
                            </MDBox>
                        </td>
                        <td>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={() => { }}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    معفى من الحد اليومي</label>
                            </MDTypography>
                        </td>
                        <td>
                            <MDBox display="flex" alignItems="center" ml={-1}>
                                <Switch checked={isExemptOfMaxSalesQtyInDay} onChange={() => setIsExemptOfMaxSalesQtyInDay(!isExemptOfMaxSalesQtyInDay)} />
                            </MDBox>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={() => { }}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    تفعيل الحساب </label>
                            </MDTypography>
                        </td>
                        <td>
                            <MDBox display="flex" alignItems="center" ml={-1}>
                                <Switch checked={isActive} disabled={true} onChange={() => setIsActive(!isActive)} />
                            </MDBox>
                        </td>
                        <td>
                            {
                                isActive
                                    ?
                                    <MDButton variant="gradient" color="error" fullWidth onClick={deActivateAccount}>
                                        إلغاء تفعيل الحساب
                                    </MDButton>
                                    :
                                    <MDButton variant="gradient" color="success" fullWidth onClick={activateAccount}>
                                        تفعيل الحساب
                                    </MDButton>

                            }
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    {waiting ? (
                        <CircularProgress color="success" />
                    ) : (
                        <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                            حفظ
                        </MDButton>
                    )}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton variant="gradient" color="primary" fullWidth onClick={() => navigate('/customers')}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
        </DashboardLayout>
    );
};

export default withAuth(CustomerDetails);
