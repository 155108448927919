import { CircularProgress } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import balanceService from 'services/balanceService';

const BalanceModal = (props) => {
    const [amount, setAmount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    function onSubmit() {
        setIsLoading(true)
        if (props.isAdd) {
            balanceService.addBalance(props.userId, amount).then(res => {
                setIsLoading(false);
            })
        } else {
            balanceService.withdrawBalance(props.userId, amount).then(res => {
                setIsLoading(false);
            })
        }
    }
    return (
        <Modal show={props.isOpen} onHide={() => { props.onClose() }}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Col>
                    <Form.Label>المستخدم : </Form.Label>
                    <Form.Text>{props.name}</Form.Text>
                    <Row>
                        <Form.Label>المبلغ</Form.Label>
                    </Row>
                    <Row>
                        <Form.Control type='number' value={amount} onChange={e => setAmount(e.target.value)}></Form.Control>
                        {
                            isLoading
                                ?
                                <Row className='justify-content-center'>
                                    <CircularProgress color={props.isAdd ? 'success' : 'danger'} size={60} />
                                </Row>
                                :
                                <Button className='mt-2'
                                    variant={props.isAdd ? 'success' : 'danger'}
                                    onClick={onSubmit}
                                >
                                    {props.isAdd ? 'إضافة' : 'سحب'}
                                </Button>
                        }
                    </Row>
                </Col>
            </Modal.Body>
        </Modal>
    );
}

export default BalanceModal;
