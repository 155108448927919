import SearchList from 'components/SearchList';
import React, { useState } from 'react';
import MDButton from 'components/MDButton';
import { useEffect } from 'react';
import supplierService from 'services/supplierService';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MDInput from 'components/MDInput';
import soService from 'services/soService';

const SupplyerModal = (props) => {
    const [suppliersData, setSuppliersData] = useState([]);
    const [supplierId, setSupplierId] = useState(1);
    const [cost, setCost] = useState(0);
    const [notes, setNotes] = useState("");

    function handleSupplierChange(value) {
        setSupplierId(value);
    }

    function onSubmit() {
        soService.processSoDtlCharge(props.soDtls, notes, cost, supplierId).then(res => {
            if (res) {
                console.log("res : ", res);
                props.cb();
            }
        });
    }

    useEffect(() => {
        supplierService.getLookups().then(res => {
            setSuppliersData(res);
        })
    }, []);
    return (
        <Modal show={props.isOpen} onHide={() => { props.onClose() }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                    المورد </label>
                <SearchList
                    data={suppliersData}
                    fiels={["name", "id"]}
                    take={10}
                    value={supplierId}
                    onChange={(id, name) => handleSupplierChange(id)}
                />
                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                    التكلفة</label>
                <MDInput value={cost} size="small" fullWidth onChange={e => { setCost(e.target.value) }} />
                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                    ملاحظات</label>
                <MDInput value={notes} size="small" fullWidth onChange={e => { setNotes(e.target.value) }} />
            </Modal.Body>
            <Modal.Footer>
                <MDButton
                    variant="gradient"
                    color="success"
                    fullWidth
                    onClick={onSubmit}>
                    حفظ
                </MDButton>
            </Modal.Footer>
        </Modal>
    );
}

export default SupplyerModal;
