import HttpService from '../utility/httpService';
class customerService {

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_Customers/GetCustomerById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_Customers/AddCustomer", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Customers/UpdateCustomer", data);
        return response;
    }
}

export default new customerService();