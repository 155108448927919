import HttpService from '../utility/httpService';
class poService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_PO/POList");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_PO/POById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_PO/POAdd", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.post("/Admin/CMS_PO/POEdit", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_PO/PODelete?id=" + id);
        return response;
    }
}

export default new poService();