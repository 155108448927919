import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Toolbar, Filter } from '@syncfusion/ej2-react-treegrid';
import './index.css'
import { Icon, IconButton } from '@mui/material';
import { navbarIconButton } from 'examples/Navbars/DashboardNavbar/styles';
import MDBox from 'components/MDBox';
function MyTreeGrid(props) {
	var allData = []


	function getColumns() {
		var columns = [];
		if (props.columns?.length > 0) {
			columns = props.columns;
		} else {
			columns = Object.keys(props.source[0]).map(key => {
				return { field: key, headerText: key, width: 90, textAlign: "right" }
			});
		}
		return columns.map((column, idx) => {
			return (
				<ColumnDirective key={idx} field={column.field} headerText={column.headerText} width={column.width} textAlign={column.textAlign}></ColumnDirective>
			)
		})
	}

	function getActionButtons(data) {
		allData.push(data)
		var buttons = props.actionButtons?.length === 0 ? [] : props.actionButtons;
		return buttons.map((button, idx) => {
			return (
				<IconButton key={idx} sx={navbarIconButton} size="small" disableRipple onClick={() => {
					// event.preventDefault();
					button.action({ data: allData, item: data })
				}}>
					{button.content}
					{/* <Icon>{button.icon}</Icon> */}
				</IconButton>
			)
		})
	}
	let treegrid;
	const dataBound = () => {
		Object.assign(treegrid.grid.filterModule.filterOperators, { startsWith: 'contains' });
	};

	const toolbarOptions = ['CollapseAll', 'ExpandAll'];

	return (<div className='control-pane'>
		<div className='control-section'>
			<MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="3.25rem"
				height="3.25rem"
				bgColor="white"
				shadow="sm"
				borderRadius="50%"
				margin="5px"
				right="2rem"
				bottom="2rem"
				zIndex={99}
				backgroundColor="green !important"
				color="white"
				sx={{ cursor: "pointer" }}
				onClick={() => props.addAction(props.source)}
			>
				<Icon fontSize="medium" color="inherit">
					add
				</Icon>
			</MDBox>
			<TreeGridComponent
				ref={g => treegrid = g}
				dataBound={dataBound}
				dataSource={props.source ? props.source : []}
				toolbar={toolbarOptions}
				allowFiltering={true}
				height='410'
				idMapping='id'
				parentIdMapping='parentId'
			>
				<ColumnsDirective>
					{getColumns()}
					<ColumnDirective headerText='' width='90' template={(props) => {
						return getActionButtons(props);
					}} textAlign='Center' />
				</ColumnsDirective>
				<Inject services={[Toolbar, Filter]} />
			</TreeGridComponent>
		</div>
	</div>
	);
}


export default MyTreeGrid;