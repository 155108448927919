import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomerClassModel from "models/CustomerClassModel";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import customerClassService from "services/customerClassService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from "react-toastify";
import { useEffect } from "react";
import withAuth from "functions/withAuth";

function CustomerClasseDetails(params) {
    let navigate = useNavigate();
    const { id } = useParams();

    const [name, setName] = useState("");
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if (Number(id)) {
            customerClassService.getById(id).then(item => {
                setName(item.className);
            })
        }
    }, []);

    function handleNameChange(value) {
        setName(value);
    }

    function handleSubmit() {
        let newCustomerClass = new CustomerClassModel(id ? id : 0, name);
        if (String(name).trim() === "" || !name) {
            toast.error("لايمكن ان يكون الأسم فارغا");
            return;
        }
        setWaiting(true);
        if (Number(id)) {
            customerClassService.update(newCustomerClass.toJson()).then(res => {
                setWaiting(false);
            })
        } else {
            customerClassService.create(newCustomerClass.toJson()).then(res => {
                if (res.isSuccess) {
                    navigate("/customerClasses");
                }
                setWaiting(false);
            })
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ backgroundColor: "white" }}>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    اسم التصنيف </label>
                            </td>
                            <td>
                                <MDInput value={name} size="small" fullWidth onChange={e => handleNameChange(e.target.value)} />
                            </td>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <div style={{ display: "flex" }}>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        {waiting ? <CircularProgress color="success" /> : <MDButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={handleSubmit}>
                            حفظ
                        </MDButton>}
                    </MDBox>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        <MDButton
                            variant="gradient"
                            color="primary"
                            fullWidth
                            onClick={() => navigate("/customerClasses")}>
                            الرجوع إلى القائمة
                            <ArrowBackIcon style={{ margin: "0 10px" }} />
                        </MDButton>
                    </MDBox>
                </div>
            </div>

            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(CustomerClasseDetails);