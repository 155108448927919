import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import newsService from 'services/newsService';

const NewsDetails = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [text, setText] = useState("");
    const [endDate, setEndDate] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const isEditMode = Number(id);

    function validate(news) {
        var errorFileds = [];
        if (String(news.text).trim() === "") {
            errorFileds.push("العنوان");
        }
        if (!news.endDate) {
            errorFileds.push("تاريخ الانتهاء");
        }
        if (errorFileds.length > 0) {
            let errorFiledsString = errorFileds.join();
            toast.error("يرجى ملئ الحقول : " + errorFiledsString)
            return false
        }
        return true
    }

    function handleSubmit() {
        setWaiting(true);
        var news = {
            "id": id,
            "text": text,
            "endDate": endDate,
        }
        if (!validate(news)) {
            setWaiting(false);
            return false;
        }
        if (isEditMode) {
            newsService.update(news).then(res => {
                setWaiting(false);
            })
        } else {
            newsService.create(news).then(res => {
                navigate("/news");
            })
        }
    }

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (isEditMode) {
                let news = await newsService.getById(id);
                if (isMounted) {
                    setText(news.text);
                    // this prcees is to convert date from ISO to natural date
                    const isoDateString = news.endDate;
                    const isoDate = new Date(isoDateString);
                    const year = isoDate.getFullYear();
                    const month = String(isoDate.getMonth() + 1).padStart(2, '0');
                    const day = String(isoDate.getDate()).padStart(2, '0');
                    const formattedDate = `${year}-${month}-${day}`;
                    setEndDate(formattedDate);
                }
            }
            setIsDataLoaded(true);
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id]);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row>
                <Col md={2}>
                    <Form.Label>الخبر</Form.Label>
                    <Form.Control value={text} onChange={e => setText(e.target.value)}></Form.Control>
                </Col>
                <Col md={2}>
                    <Form.Label>تاريخ الانتهاء</Form.Label>
                    <Form.Control
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        type='date'></Form.Control>
                </Col>
            </Row>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px">
                    {waiting ? <CircularProgress color="success" /> : <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}>
                        حفظ
                    </MDButton>}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/news")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default NewsDetails;
