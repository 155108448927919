import HttpService from '../utility/httpService';
class CategoryService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_Categories/GetAllCategories");
        return response;
    }

    async createCategory(categoryData) {
        const response = await HttpService.post("/Admin/CMS_Categories/AddCategory", categoryData);
        return response;
    }

    async updateCategory(categoryData) {
        const response = await HttpService.put("/Admin/CMS_Categories/EditCategory", categoryData);
        return response;
    }

    async deleteCategory(id) {
        const response = await HttpService.delete("/Admin/CMS_Categories/DeleteCategory?id=" + id);
        return response;
    }
}

export default new CategoryService();