import HttpService from '../utility/httpService';
class adminService {
    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_Users/GetAdminById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_Users/AddAdmin", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Users/UpdateAdmin", data);
        return response;
    }

    async getRoles() {
        const response = await HttpService.get("/Admin/CMS_Lookups/RolesLookup");
        return response;
    }
}

export default new adminService();