import { useEffect, useState } from 'react';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import "../Category/details.css";
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import categoryService from 'services/categoryService';
import { CircularProgress, IconButton, Switch } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MyGridView from 'components/GridView';
import SearchList from 'components/SearchList';
import customerClassService from 'services/customerClassService';
import itemService from 'services/itemService';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import itemStockService from 'services/itemStockService';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import withAuth from 'functions/withAuth';
import { Col, Form, Row } from 'react-bootstrap';

function ItemDetails(props) {
    let navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Number(id);

    const [nameAr, setNameAr] = useState("");
    const [descAr, setDescAr] = useState("");
    const [order, setOrder] = useState(0);
    const [cost, setCost] = useState(0);
    const [price, setPrice] = useState(0);
    const [minQty, setMinQty] = useState(0);
    const [maxQty, setMaxQty] = useState(0);
    const [chargeServicePrice, setChargeServicePrice] = useState(0);
    const [maxSalesQtyDaily, setMaxSalesQtyDaily] = useState(0);
    const [chargeNotes, setChargeNotes] = useState("");
    const [img, setImg] = useState("");
    const [imgFile, setImgFile] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [isAllowCardSales, setIsAllowCardSales] = useState(true);
    const [isAllowChargeSales, setIsAllowChargeSales] = useState(true);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isMostSold, setIsMostSold] = useState(true);
    const [categoryId, setCategoryId] = useState(null);
    const [field, setField] = useState(0);
    const [fieldName, setFieldName] = useState("");
    const [fieldRequired, setFieldRequired] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [stockItems, setStockItems] = useState([]);
    const [stockItemsId, setStockItemsId] = useState(null);
    const [customerClassPrices, setCustomerClassPrices] = useState([]);
    const [customerClasses, setCustomerClasses] = useState([]);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [itemType, setItemType] = useState(0);
    const [extraFields, setExtraFields] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [isCompressed1, setIsCompressed1] = useState(false);
    const [isCompressed2, setIsCompressed2] = useState(true);
    const [isCompressed3, setIsCompressed3] = useState(true);
    const [isCompressed4, setIsCompressed4] = useState(true);
    const [isCompressed5, setIsCompressed5] = useState(true);

    var itemDescRef, chargeNotesRef;
    const toggleDiv = (num) => {
        switch (num) {
            case 1:
                setIsCompressed1(!isCompressed1);
                break;
            case 2:
                setIsCompressed2(!isCompressed2);
                break;
            case 3:
                setIsCompressed3(!isCompressed3);
                break;
            case 4:
                setIsCompressed4(!isCompressed4);
                break;
            case 5:
                setIsCompressed5(!isCompressed5);
                break;
            default:
                break;
        }
    };
    function handleImgChange(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImgFile(reader.result);
            setImg(reader.result)
        };
        reader.onerror = error => {
            console.error("Error: ", error);
        };
    }

    function addAdditionalField() {
        if (field !== "") {
            let extraField = {
                "itemId": 0,
                "extraFieldId": field,
                "extraFieldName": fieldName,
                "isRequired": fieldRequired
            }
            if (additionalFields.find(x => x.extraFieldId === extraField.extraFieldId)) return;
            let arr = [...additionalFields];
            arr.push(extraField);
            setAdditionalFields(arr);
            setField("");
            setFieldRequired(true);
        }
    }
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const categories = await categoryService.getAll();
                const extraFields = await itemService.getExtraFields();
                const customerclassesData = await customerClassService.getAll();
                const stockItems = await itemStockService.getLookups();
                if (isMounted) {
                    setStockItems(stockItems);
                    setExtraFields(extraFields);
                    setCustomerClasses(customerclassesData.map(e => {
                        return {
                            id: e.id,
                            name: e.className,
                        }
                    }));
                    setCategories(categories.map(e => {
                        return {
                            id: e.id,
                            name: e.catName
                        }
                    }));
                }
                if (isEditMode) {
                    const item = await itemService.getById(id)
                    if (isMounted) {
                        try {
                            setNameAr(item.itemNameAR);
                            setDescAr(item.itemDescAR);
                            setOrder(item.itemOrder);
                            setStockItemsId(item.itemStockId);
                            setCost(item.avgCost);
                            setPrice(item.price);
                            setMinQty(item.minSalesQty);
                            setMaxQty(item.maxSalesQty);
                            setChargeServicePrice(item.chargeServicePrice);
                            setChargeNotes(item.chargeNotes ? item.chargeNotes : "");
                            setImg(item.img);
                            setImgFile(item.imgFile ? item.imgFile : "");
                            setIsActive(item.isActive);
                            setIsAllowCardSales(item.isAllowCardSales);
                            setIsAllowChargeSales(item.isAllowChargeSales);
                            setIsFeatured(item.isFeatured);
                            setIsMostSold(item.isMostSold);
                            setCategoryId(item.categoryId);
                            setItemType(item.sellType);
                            setCustomerClassPrices(item.itemPrices);
                            setAdditionalFields(item.itemExtraFields);

                            setIsDataLoaded(true);
                        } catch (error) {
                            setIsDataLoaded(true);
                        }
                    } else {
                        setIsDataLoaded(true);
                    }
                } else {
                    setCustomerClassPrices(customerclassesData.map(e => {
                        return {
                            id: e.id,
                            customerClassName: e.className,
                            price: 0,
                        }
                    }));
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        return () => {
            // Cleanup function
            isMounted = false;
            // Cancel any ongoing asynchronous tasks or subscriptions here
            // ...
        };
    }, [isEditMode, id]);

    function handleSubmit() {
        if (cost === 0) {
            toast.error("لايمكن أن تكون الكلفة صفراَ");
            return;
        }
        if (nameAr === "") {
            toast.error("اسم المادة مطلوب!");
            return;
        }
        if (!categoryId) {
            toast.error("يجب إختيار العائلة!");
            return;
        }
        if (!stockItemsId) {
            toast.error("يجب إختيار مادة المخزن!");
            return;
        }
        setWaiting(true);
        const newItem = {
            "id": Number(id) ? id : 0,
            "categoryId": categoryId,
            "itemStockId": stockItemsId,
            "itemNameAR": nameAr,
            "itemDescAR": itemDescRef ? itemDescRef.value : descAr,
            "avgCost": cost,
            "price": price,
            "agentCommissionValue": 0,
            "suggestedPrice": 0,
            "sellType": itemType,
            "minSalesQty": minQty,
            "maxSalesQty": maxQty,
            "maxSalesQtyInDay": maxSalesQtyDaily,
            "chargeServicePrice": chargeServicePrice,
            "chargeNotes": chargeNotesRef ? chargeNotesRef.value : chargeNotes,
            "img": img,
            "imgFile": imgFile,
            "isActive": isActive,
            "isAllowCardSales": isAllowCardSales,
            "isAllowChargeSales": isAllowChargeSales,
            "itemOrder": order,
            "isFeatured": isFeatured,
            "isMostSold": isMostSold,
            "itemPrices": customerClassPrices.map(e => {
                return { id: 0, customerClassId: e.id, customerClassName: e.customerClassName, price: e.price }
            }),
            "itemExtraFields": additionalFields
        }

        if (Number(id)) {
            itemService.update(newItem).then(res => {
                setWaiting(false);
            });
        } else {
            itemService.create(newItem).then(res => {
                if (res) {
                    navigate("/items");
                }
                setWaiting(false);
            })
        }
    }

    function handleActionComplete(args) {
        if (args.requestType === "save") {
            let arr = customerClassPrices.map(e => {
                if (e.id === args.data.id) {
                    e.price = args.data.price;
                }
                return e;
            });
            setCustomerClassPrices(arr);
        }
    }

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ backgroundColor: "white" }}>
                <div style={{ border: '1px solid gray', marginBottom: "10px" }}>
                    <div style={{ backgroundColor: "gray", display: 'flex', alignItems: "center" }}>
                        <IconButton onClick={() => { toggleDiv(1) }}>
                            {isCompressed1 ? <KeyboardArrowDownIcon color='success' /> : <KeyboardArrowUpIcon color='success' />}
                        </IconButton>
                        <p style={{ margin: "5px", color: "white" }}>المعلومات الرئيسية</p>
                    </div>
                    {isCompressed1 ? (
                        <></>
                    ) : (
                        <div style={{ overflow: 'hidden' }}>
                            <Row className='m-2'>
                                <Col md={3}>
                                    <Form.Label>اسم المنتج</Form.Label>
                                    <Form.Control type="text" value={nameAr} onChange={e => setNameAr(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>الترتيب</Form.Label>
                                    <Form.Control type="number" value={order} onChange={e => setOrder(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>الكلفة</Form.Label>
                                    <Form.Control type="number" value={cost} onChange={e => setCost(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>الحد الأدنى لكمية البيع</Form.Label>
                                    <Form.Control type="number" value={minQty} onChange={e => setMinQty(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>السعر</Form.Label>
                                    <Form.Control type="number" value={price} onChange={e => setPrice(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>العائلة</Form.Label>
                                    <SearchList
                                        data={categories}
                                        fiels={["catName", "id"]}
                                        take={10}
                                        value={categoryId}
                                        onChange={(id, name) => {
                                            setCategoryId(id);
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>مادة المخزون</Form.Label>
                                    <SearchList
                                        data={stockItems}
                                        fiels={["name", "id"]}
                                        take={10}
                                        value={stockItemsId}
                                        onChange={(id, name) => {
                                            console.log('id : ', id);
                                            setStockItemsId(id);
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>الحد الأعلى لكمية البيع</Form.Label>
                                    <Form.Control type="number" value={maxQty} onChange={e => setMaxQty(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>الحد الأعلى لكمية البيع يوميا</Form.Label>
                                    <Form.Control type="number" value={maxSalesQtyDaily} onChange={e => setMaxSalesQtyDaily(e.target.value)}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>نوع المنتج</Form.Label>
                                    <SearchList
                                        data={[{ "id": 0, "name": "شحن", }, { "id": 1, "name": "بطاقات", }]}
                                        fiels={["name", "id"]}
                                        take={10}
                                        value={itemType}
                                        onChange={(id, name) => {
                                            setItemType(id);
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <MDBox display="flex" alignItems="center" ml={-1}>
                                        <Switch checked={isActive} onChange={() => setIsActive(!isActive)} />
                                        <MDTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            onClick={() => { }}
                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                        >
                                            &nbsp;&nbsp;فعالة
                                        </MDTypography>
                                    </MDBox>
                                </Col>
                                <Col md={3}>
                                    <MDBox display="flex" alignItems="center" ml={-1}>
                                        <Switch checked={isFeatured} onChange={() => setIsFeatured(!isFeatured)} />
                                        <MDTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            onClick={() => { }}
                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                        >
                                            &nbsp;&nbsp; متميزة
                                        </MDTypography>
                                    </MDBox>
                                </Col>
                            </Row>
                            <div style={{ width: "250px", padding: "5px" }}>
                                <img style={{ width: "100px" }} src={img} alt="Nothing to present" />
                                <MDInput accept="image/*" type="file" size="small" fullWidth onChange={e => handleImgChange(e)} />
                            </div>
                        </div>
                    )}
                </div>

                <div style={{ border: '1px solid gray', marginBottom: "10px" }}>
                    <div style={{ backgroundColor: "gray", display: 'flex', alignItems: "center" }}>
                        <IconButton onClick={() => { toggleDiv(2) }}>
                            {isCompressed2 ? <KeyboardArrowDownIcon color='success' /> : <KeyboardArrowUpIcon color='success' />}
                        </IconButton>
                        <p style={{ margin: "5px", color: "white" }}>وصف المنتج</p>
                    </div>
                    {isCompressed2 ? (
                        <></>
                    ) : (
                        <div style={{ overflow: 'hidden' }}>
                            <RichTextEditorComponent
                                ref={(richtexteditor) => { itemDescRef = richtexteditor; }}
                                value={descAr}
                                change={e => setDescAr(e.value)}
                                toolbarSettings={{
                                    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                                        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                                        'LowerCase', 'UpperCase', '|',
                                        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                                        'Outdent', 'Indent', '|',
                                        'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                                        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
                                }}>
                                <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, HtmlEditor]} />
                            </RichTextEditorComponent>
                        </div>
                    )}
                </div>

                <div style={{ border: '1px solid gray', marginBottom: "10px" }}>
                    <div style={{ backgroundColor: "gray", display: 'flex', alignItems: "center" }}>
                        <IconButton onClick={() => { toggleDiv(3) }}>
                            {isCompressed3 ? <KeyboardArrowDownIcon color='success' /> : <KeyboardArrowUpIcon color='success' />}
                        </IconButton>
                        <p style={{ margin: "5px", color: "white" }}>ملاحظات الشحن</p>
                    </div>
                    {isCompressed3 ? (
                        <></>
                    ) : (
                        <div style={{ overflow: 'hidden' }}>
                            <RichTextEditorComponent
                                ref={(richtexteditor) => { chargeNotesRef = richtexteditor; }}
                                value={chargeNotes}
                                change={e => setChargeNotes(e.value)}
                                toolbarSettings={{
                                    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                                        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                                        'LowerCase', 'UpperCase', '|',
                                        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                                        'Outdent', 'Indent', '|',
                                        'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                                        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
                                }}>
                                <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, HtmlEditor]} />
                            </RichTextEditorComponent>
                        </div>
                    )}
                </div>

                <div style={{ border: '1px solid gray', marginBottom: "10px" }}>
                    <div style={{ backgroundColor: "gray", display: 'flex', alignItems: "center" }}>
                        <IconButton onClick={() => { toggleDiv(4) }}>
                            {isCompressed4 ? <KeyboardArrowDownIcon color='success' /> : <KeyboardArrowUpIcon color='success' />}
                        </IconButton>
                        <p style={{ margin: "5px", color: "white" }}>الاسعار</p>
                    </div>
                    {isCompressed4 ? (
                        <></>
                    ) : (
                        <div style={{ overflow: 'hidden' }}>
                            <MyGridView
                                source={customerClassPrices}
                                editSettings={{ allowEditing: true, allowAdding: true, allowDeleting: true }}
                                toolbar={['Edit', 'Update', 'Cancel']}
                                actionComplete={e => handleActionComplete(e)}
                                columns={[
                                    {
                                        "field": "customerClassName",
                                        "headerText": "الصنف",
                                        "width": 70,
                                        "textAlign": "right",
                                        "allowEditing": false
                                    },
                                    {
                                        "field": "price",
                                        "headerText": "السعر",
                                        "width": 70,
                                        "textAlign": "right",
                                        "allowEditing": true
                                    }
                                ]}
                                preventPagination={false}
                                actionButtons={[]}
                                addAction={null}
                            />
                        </div>
                    )}
                </div>

                <div>
                    <div style={{ backgroundColor: "gray", display: 'flex', alignItems: "center" }}>
                        <IconButton onClick={() => { toggleDiv(5) }}>
                            {isCompressed5 ? <KeyboardArrowDownIcon color='success' /> : <KeyboardArrowUpIcon color='success' />}
                        </IconButton>
                        <p style={{ margin: "5px", color: "white" }}>الحقول الاضافية</p>
                    </div>
                    {isCompressed5 ? (
                        <></>
                    ) : (
                        <div style={{ overflow: 'hidden' }}>
                            <div style={{ display: 'flex', border: '1px solid #d2d6da', alignItems: 'end', padding: '10px', borderRadius: '5px' }}>
                                <div style={{ margin: '0 10px' }}>
                                    <MDButton
                                        variant="gradient"
                                        color="success"
                                        fullWidth
                                        onClick={addAdditionalField}>
                                        حفظ
                                    </MDButton>
                                </div>
                                <div style={{ margin: '0 10px' }}>
                                    <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                        الحقل </label>
                                    <SearchList
                                        data={extraFields}
                                        value={field}
                                        type="text"
                                        size="small"
                                        fullWidth
                                        onChange={(id, name) => {
                                            setField(id);
                                            setFieldName(name);
                                        }} />
                                </div>
                                <div style={{ margin: '0 10px' }}>
                                    <MDBox display="flex" alignItems="center" ml={-1}>
                                        <Switch checked={fieldRequired} onChange={() => setFieldRequired(!fieldRequired)} />
                                        <MDTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            onClick={() => { }}
                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                        >
                                            &nbsp;&nbsp;مطلوب
                                        </MDTypography>
                                    </MDBox>
                                </div>
                            </div>
                            <MyGridView
                                source={additionalFields}
                                editSettings={{ allowEditing: false, allowAdding: false, allowDeleting: true }}
                                toolbar={['Delete']}
                                actionComplete={() => { }}
                                columns={[
                                    {
                                        "field": "extraFieldName",
                                        "headerText": "الحقل",
                                        "width": 70,
                                        "textAlign": "right",
                                        "allowEditing": true
                                    },
                                    {
                                        "field": "isRequired",
                                        "headerText": "مطلوب",
                                        "width": 70,
                                        "textAlign": "right",
                                        "allowEditing": true
                                    }
                                ]}
                                actionButtons={[]}
                                addAction={null}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px">
                    {waiting ? <CircularProgress color="success" /> : <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}>
                        حفظ
                    </MDButton>}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/items")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default withAuth(ItemDetails);