import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import withAuth from 'functions/withAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import poService from 'services/poService';

const SOList = () => {
    const [status, setStatus] = useState("0");
    const gridViewRef = React.useRef(null);
    let navigate = useNavigate();

    function statusChangeHandler(e) {
        try {
            setStatus(e.target.value);
            document.getElementById(e.target.value).checked = true;
        } catch (error) {

        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                <div onChange={e => { statusChangeHandler(e) }}
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        backgroundColor: "#bdbdbd",
                        borderRadius: "5px",
                        padding: "5px"
                    }}>
                    <div>
                        <label for="null">الكل</label>
                        <input id="null" type="radio" value="" name="status" checked={status === ""} style={{ margin: "5px" }} />
                    </div>
                    <div>
                        <label style={{ color: "orange" }} for="0">جديد</label>
                        <input id="0" type="radio" value="0" name="status" checked={status === "0"} style={{ margin: "5px" }} />
                    </div>
                    <div>
                        <label style={{ color: "blue" }} for="1">معالجة</label>
                        <input id="1" type="radio" value="1" name="status" checked={status === "1"} style={{ margin: "5px" }} />
                    </div>
                    <div>
                        <label style={{ color: "green" }} for="2">ناجحة</label>
                        <input id="2" type="radio" value="2" name="status" checked={status === "2"} style={{ margin: "5px" }} />
                    </div>
                    <div>
                        <label style={{ color: "red" }} for="3">مرفوضة</label>
                        <input id="3" type="radio" value="3" name="status" checked={status === "3"} style={{ margin: "5px" }} />
                    </div>
                </div>
                <MyGridView
                    ref={gridViewRef}
                    url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_SO/SOList?status=${status}`}
                    columns={[
                        {
                            "field": "id",
                            "headerText": "#",
                            "width": 70,
                            "textAlign": "right"
                        },
                        {
                            "field": "orderNumber",
                            "headerText": "رقم الطلب",
                            "width": 70,
                            "textAlign": "right"
                        },
                        {
                            "field": "customerName",
                            "headerText": "العميل",
                            "width": 90,
                            "textAlign": "right"
                        },
                        {
                            "field": "soDate",
                            "headerText": "التاريخ",
                            "width": 90,
                            "type": "date",
                            "format": "dd/MM/yyyy",
                            "textAlign": "right"
                        },
                        {
                            "field": "totalPrice",
                            "headerText": "السعر",
                            "width": 90,
                            "textAlign": "right"
                        },
                        {
                            "field": "statusName",
                            "headerText": "الحالة",
                            "width": 90,
                            "textAlign": "right",
                        },
                    ]}
                    allowFiltering={true}
                    actionButtons={[
                        {
                            "content": <Icon color="info">visibility</Icon>,
                            "action": (id) => {
                                return navigate("/so/" + id)
                            }
                        },
                    ]}
                />
            </div>
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(SOList);
