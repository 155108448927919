import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import withAuth from 'functions/withAuth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from 'services/auth';
import customerClassService from 'services/customerClassService';

const CustomersClassesList = () => {
    const [data, setData] = useState([]);
    let navigate = useNavigate();

    function getAllCustomersClasses() {
        customerClassService.getAll().then(res => {
            setData([...res])
        });
    }

    useEffect(() => {
        getAllCustomersClasses();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                <MyGridView
                    source={data}
                    columns={[
                        {
                            "field": "id",
                            "headerText": "#",
                            "width": 70,
                            "textAlign": "right"
                        },
                        {
                            "field": "className",
                            "headerText": "الصنف",
                            "width": 90,
                            "textAlign": "right"
                        },
                    ]}
                    allowFiltering={true}
                    actionButtons={[
                        {
                            "content": <Icon color='info'>edit</Icon>,
                            "action": (id) => {
                                return navigate("/customerClass/" + id)
                            }
                        },
                        {
                            "content": <Icon color='error'>delete</Icon>,
                            "action": (id) => {
                                MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف!", () => {
                                    customerClassService.delete(id).then(res => {
                                        getAllCustomersClasses();
                                    });
                                })
                            }
                        },
                    ]}
                    addAction={() => {
                        return navigate("/customerClass/" + 0)
                    }}
                />
            </div>
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(CustomersClassesList);
