import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { registerLicense } from '@syncfusion/ej2-base'
// Jo Pay React Context Provider
import { MaterialUIControllerProvider } from "context";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS35Sd0BnW3pWcnFVQg==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdEViWHpacnFdRGZaVQ==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjWH5bdHZQTmJUU00=;MTA0OTU3N0AzMjMwMmUzNDJlMzBqZ3I3M0FqUWgxTExZeFB3bm9NOXBIL1p1YWhlWXp4L09Yai9QN2gwUGprPQ==;MTA0OTU3OEAzMjMwMmUzNDJlMzBTRnduU3lLdm9RRWpoTEFQTFAxUXJPK2NxRWdBbGFtNUR1RlU0dmdPelFBPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhWX5fdXBXQ2hfVkF3;MTA0OTU4MEAzMjMwMmUzNDJlMzBWMnZoMDU0NVhPb3grYTNILzdXRjlpVHhaVUJSekdLelByL0xraDJvd3VVPQ==;MTA0OTU4MUAzMjMwMmUzNDJlMzBleVdyT0dCNFNmUnkyZ0FUWWVDWjZPVFZCRlJNdzB3OHVSMlBqRXRUWGRnPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjWH5bdHZQTmRZV0Y=;MTA0OTU4M0AzMjMwMmUzNDJlMzBUbXJ2ZjZ4TE5ReHpGOTlXWFNVbGk3UXdCWVh3R0NrYTljK01DbGNvVFpVPQ==;MTA0OTU4NEAzMjMwMmUzNDJlMzBvNm53ZWVtOVhadERiV1pndkwwcDF5aUlPYW1Rb2tVUG5KcFRqZzFBa29RPQ==;MTA0OTU4NUAzMjMwMmUzNDJlMzBWMnZoMDU0NVhPb3grYTNILzdXRjlpVHhaVUJSekdLelByL0xraDJvd3VVPQ==');

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
