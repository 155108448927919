import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import BalanceModal from './balanceModel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ManageBalance = (props) => {
    const navigate = useNavigate();
    const [modalState, setModalState] = useState({ isAdd: false, isOpen: false });

    return (
        <>
            <Row className='mb-2'>
                <Col md={1}>
                    <Button
                        onClick={() => navigate('/transactionList/' + props.userId)}
                        variant='primary'
                    >كشف حساب</Button>
                </Col>
                <Col md={1}>
                    <Button
                        onClick={() => setModalState({ ...modalState, isOpen: true, isAdd: true })}
                        variant='success'
                    >إضافة رصيد
                    </Button>
                </Col>
                <Col md={1}>
                    <Button
                        onClick={() => setModalState({ ...modalState, isOpen: true, isAdd: false })}
                        variant='danger'
                    >سحب رصيد
                    </Button>
                </Col>
            </Row>
            <BalanceModal
                userId={props.userId}
                isOpen={modalState.isOpen}
                onClose={() => setModalState({ ...modalState, isOpen: false })}
                name={props.name}
                isAdd={modalState.isAdd}
            />
        </>
    );
}

export default ManageBalance;
