import HttpService from '../utility/httpService';
class SoService {

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_SO/SOById?id=" + id);
        return response;
    }

    async acceptSo(id) {
        const response = await HttpService.post("/Admin/CMS_SO/AcceptSO?id=" + id);
        return response;
    }

    async rejectSo(id, reson) {
        const response = await HttpService.post(`/Admin/CMS_SO/RejectSO?id=${id}&rejectReason=${reson}`);
        return response;
    }

    async completeSo(id) {
        const response = await HttpService.post(`/Admin/CMS_SO/CompleteSO?id=${id}`);
        return response;
    }

    async rejectSoItem(id) {
        const response = await HttpService.post(`/Admin/CMS_SO/RejectSoDtl?soDtlId=${id}`);
        return response;
    }

    async getITemFromTheInventory(id) {
        const response = await HttpService.post(`/Admin/CMS_SO/RejectSoDtl?ProcessSoDtlBarcodeManualStock?soDtlId=${id}`);
        return response;
    }

    async processSoDtlCharge(id, notes, cost, supplier_id) {
        const response = await HttpService.post(`/Admin/CMS_SO/ProcessSoDtlCharge?soDtlId=${id}&cost=${cost}&supplierName=${supplier_id}&notes=${notes}`);
        return response;
    }

    async saveAdminNotes(id, notes) {
        const response = await HttpService.post(`/Admin/CMS_SO/SaveAdminNotes?id=${id}&notes=${notes}`);
        return response;
    }

}

export default new SoService();