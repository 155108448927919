import { Query } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import * as React from 'react';
export default class SearchList extends React.Component {
    // maps the appropriate column to fields property
    fields = { text: "name", value: "id" };
    // define the filtering data
    // filtering event handler to filter a Country
    onFiltering(e) {
        // set limit as 4 to search result
        let query = new Query().select(['name', 'id']);
        query = (e.text !== '') ? query
            .where("name", 'contains', e.text, true) : query;
        e.updateData(this.props.data, query);
    }
    render() {
        return (
            <DropDownListComponent
                id="ddlelement"
                popupHeight="250px"
                fields={this.fields}
                filtering={this.onFiltering = this.onFiltering.bind(this)}
                allowFiltering={true}
                dataSource={this.props.data}
                value={this.props.value}
                onChange={(e) => {
                    let name = this.props.data.find(x => x.id === e.value).name;
                    if (this.props.onChange) {
                        this.props.onChange(e.value, name);
                    }
                }}
                placeholder={this.props.placeholder ? this.props.placeholder : "Select Option"} />
        );
    }
}