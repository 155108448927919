import { CircularProgress, Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dynamicPagesService from 'services/dynamicPagesService';

const DynamicPages = () => {
    const [data, setData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    let navigate = useNavigate();

    function getAllData() {
        dynamicPagesService.getAll().then(res => {
            setData([...res]);
            setIsDataLoaded(true);
        });
    }

    useEffect(() => {
        getAllData();
    }, []);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                <MyGridView
                    source={data}
                    columns={[
                        {
                            "field": "title",
                            "headerText": "الصفحة",
                            "width": 70,
                            "textAlign": "right"
                        },
                    ]}
                    allowFiltering={true}
                    actionButtons={[
                        {
                            "content": <Icon color='info'>edit</Icon>,
                            "action": (id) => {
                                return navigate("/dynamicPages/" + id)
                            }
                        },
                        {
                            "content": <Icon color='error'>delete</Icon>,
                            "action": (id) => {
                                MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف!", () => {
                                    dynamicPagesService.delete(id).then(res => {
                                        getAllData();
                                    });
                                })
                            }
                        },
                    ]}
                    addAction={() => {
                        return navigate("/dynamicPages/" + 0)
                    }}
                />
            </div>
            <Footer />
        </DashboardLayout>
    );
}

export default DynamicPages;
