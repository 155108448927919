import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import withAuth from 'functions/withAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import poService from 'services/poService';

const POList = () => {
    let navigate = useNavigate();
    const gridViewRef = React.useRef(null);

    const refreshGridView = () => {
        gridViewRef.current.refresh();
    };

    let url = `${process.env.REACT_APP_BASE_URL}/Admin/CMS_PO/POList`
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                <MyGridView
                    ref={gridViewRef}
                    url={url}
                    columns={[
                        {
                            "field": "id",
                            "headerText": "#",
                            "width": 70,
                            "textAlign": "right"
                        },
                        {
                            "field": "poNumber",
                            "headerText": "الفاتورة",
                            "width": 90,
                            "textAlign": "right"
                        },
                        {
                            "field": "supplierName",
                            "headerText": "المورد",
                            "width": 90,
                            "textAlign": "right"
                        },
                        {
                            "field": "poDate",
                            "headerText": "التاريخ",
                            "width": 90,
                            "type": "date",
                            "format": "dd/MM/yyyy",
                            "textAlign": "right"
                        },
                        {
                            "field": "totalCost",
                            "headerText": "الكلفة",
                            "width": 90,
                            "format": "n3",
                            "textAlign": "right"
                        },
                        {
                            "field": "notes",
                            "headerText": "ملاحظات",
                            "width": 90,
                            "textAlign": "right"
                        },
                        {
                            "field": "isPosted",
                            "headerText": "مرحل",
                            "width": 90,
                            "textAlign": "right",
                            'displayAsCheckBox': "true",
                            'editType': "booleanedit",
                            'type': "boolean",
                            'clipMode': "EllipsisWithTooltip",
                            'filterTemplate': () => false
                        },
                    ]}
                    allowFiltering={true}
                    actionButtons={[
                        {
                            "content": <Icon color="info">edit</Icon>,
                            "action": (id) => {
                                return navigate("/po/" + id)
                            }
                        },
                        {
                            "content": <Icon color="error">delete</Icon>,
                            "action": (id) => {
                                MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف!", () => {
                                    poService.delete(id).then(res => {
                                        refreshGridView();
                                    });
                                })
                            }
                        },
                    ]}
                    addAction={() => {
                        return navigate("/po/" + 0);
                    }}
                />
            </div>
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(POList);
