import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import itemService from 'services/itemService';
import categoryService from 'services/categoryService';
import SearchList from 'components/SearchList';
import offersService from 'services/offersService';
import { toast } from 'react-toastify';

const OfferDetails = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [type, setType] = useState(0);
    const [endDate, setEndDate] = useState("");
    const [img, setImg] = useState("");
    const [imgFile, setImgFile] = useState(null);
    const [refrenceId, setRefrenceId] = useState(null);
    const [url, setUrl] = useState("");
    const [categories, setCategories] = useState([]);
    const [Products, setProducts] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const isEditMode = Number(id);

    function validateOffer(offer) {
        var errorFileds = [];
        if (String(offer.offerTitle).trim() === "") {
            errorFileds.push("الاسم");
        }
        if (!offer.endDate) {
            errorFileds.push("تاريخ الانتهاء");
        }
        if (offer.offerType !== 0 && !offer.refrenceId) {
            if (offer.offerType !== "3" && String(offer.url).trim() === "") {
                errorFileds.push("تفاصيل النوع");
            }
        }
        if (errorFileds.length > 0) {
            let errorFiledsString = errorFileds.join();
            toast.error("يرجى ملئ الحقول : " + errorFiledsString)
            return false
        }
        return true
    }

    function handleSubmit() {
        setWaiting(true);
        var newOffer = {
            "id": id,
            "offerType": type,
            "offerTitle": name,
            "offerDescription": desc,
            "endDate": endDate ? new Date(endDate).toISOString() : null,
            "offerImgFile": imgFile,
            "refrenceId": refrenceId,
            "url": url
        }
        if (!validateOffer(newOffer)) {
            setWaiting(false);
            return false;
        }
        if (isEditMode) {
            offersService.update(newOffer).then(res => {
                setWaiting(false);
            })
        } else {
            offersService.create(newOffer).then(res => {
                navigate("/offers");
            })
        }
    }

    function handleImgChange(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImgFile(reader.result);
            setImg(reader.result)
        };
        reader.onerror = error => {
            console.error("Error: ", error);
        };
    }

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            let items = await itemService.getLookups();
            let categoriesArr = await categoryService.getAll();
            if (isMounted) {
                setProducts([...items])
                setCategories(categoriesArr.map(e => { return { 'id': e.id, 'name': e.catName } }))
            }
            if (isEditMode) {
                let offer = await offersService.getById(id);
                if (isMounted) {
                    setName(offer.offerTitle);
                    setDesc(offer.offerDescription);
                    setType(offer.offerType);
                    setImg(offer.offerImg);
                    setRefrenceId(offer.refrenceId);
                    setUrl(offer.url);
                    // this prcees is to convert date from ISO to natural date
                    const isoDateString = offer.endDate;
                    const isoDate = new Date(isoDateString);
                    const year = isoDate.getFullYear();
                    const month = String(isoDate.getMonth() + 1).padStart(2, '0');
                    const day = String(isoDate.getDate()).padStart(2, '0');
                    const formattedDate = `${year}-${month}-${day}`;
                    setEndDate(formattedDate);
                }
            }
            setIsDataLoaded(true);
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id]);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row>
                <Col md={2}>
                    <Form.Label>اسم العرض</Form.Label>
                    <Form.Control value={name} onChange={e => setName(e.target.value)}></Form.Control>
                </Col>
                <Col md={2}>
                    <Form.Label>شرح العرض</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                    ></Form.Control>
                </Col>
                <Col md={2}>
                    <div style={{ width: "250px", padding: "5px" }}>
                        <img style={{ width: "100px" }} src={process.env.REACT_APP_BASE_URL + "/" + img} alt="Nothing to present" />
                        <MDInput accept="image/*" type="file" size="small" fullWidth onChange={e => handleImgChange(e)} />
                    </div>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col md={2}>
                    <Form.Label>تاريخ الانتهاء</Form.Label>
                    <Form.Control
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        type='date'></Form.Control>
                </Col>
                <Col md={2}>
                    <Form.Label>النوع</Form.Label>
                    <Form.Select value={type}
                        onChange={e => {
                            setType(Number(e.target.value));
                            setRefrenceId(null);
                            setUrl("");
                        }}>
                        <option value="0">None</option>
                        <option value="1">Product</option>
                        <option value="2">Category</option>
                        <option value="3">URL</option>
                    </Form.Select>
                </Col>
                <Col md={2} className='align-self-end'>
                    {
                        Number(type) === 1
                            ?
                            <>
                                <Form.Label>تفاصيل النوع</Form.Label>
                                <SearchList
                                    data={Products}
                                    fiels={["catName", "id"]}
                                    take={10}
                                    value={refrenceId}
                                    onChange={(id, name) => {
                                        setRefrenceId(id);
                                    }}
                                />
                            </>
                            :
                            Number(type) === 2
                                ?
                                <>
                                    <Form.Label>تفاصيل النوع</Form.Label>
                                    <SearchList
                                        data={categories}
                                        fiels={["catName", "id"]}
                                        take={10}
                                        value={refrenceId}
                                        onChange={(id, name) => {
                                            setRefrenceId(id);
                                        }}
                                    />
                                </>
                                :
                                Number(type) === 3
                                    ?
                                    <>
                                        <Form.Label>تفاصيل النوع</Form.Label>
                                        <Form.Control value={url} onChange={e => setUrl(e.target.value)}></Form.Control>
                                    </>
                                    :
                                    <></>
                    }
                </Col>
            </Row>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px">
                    {waiting ? <CircularProgress color="success" /> : <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}>
                        حفظ
                    </MDButton>}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/offers")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default OfferDetails;
