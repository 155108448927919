import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import withAuth from 'functions/withAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomersList = () => {
    let navigate = useNavigate();
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyGridView
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_Customers/CustomersList`}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "fullName",
                        "headerText": "الاسم",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "email",
                        "headerText": "البريد الالكتروني",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "className",
                        "headerText": "الصنف",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "phoneNumber",
                        "headerText": "رقم الهاتف",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "currentBalance",
                        "headerText": "الرصيد",
                        "width": 90,
                        "textAlign": "right"
                    },
                ]}
                allowFiltering={true}
                actionButtons={[
                    {
                        "content": <Icon color='info'>edit</Icon>,
                        "action": (id) => navigate("/customers/" + id)
                    },
                ]}
                addAction={() => navigate("/customers/" + 0)}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(CustomersList);
