import HttpService from '../utility/httpService';
class itemsService {

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_Items/GetItemById?id=" + id);
        return response;
    }

    async getExtraFields() {
        const response = await HttpService.get("/Admin/CMS_Lookups/ExtraFields");
        return response;
    }

    async getLookups() {
        const response = await HttpService.get("/Admin/CMS_Lookups/ItemsLookup");
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_Items/ItemAdd", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Items/ItemUpdate", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_Items/ItemDelete?id=" + id);
        return response;
    }
}

export default new itemsService();