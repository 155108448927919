import { toast } from 'react-toastify';
import auth from '../services/auth';

class HttpService {

    async get(action) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}` + action, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.getToken()}`
                }
            });
            const res = await response.json();
            if (res.isSuccess) {
                return res.data;
            } else {
                toast.error(JSON.stringify(res.errors));
                return null;
            }
        } catch (error) {
            toast.error(error);
            return null;
        }
    }

    async postList(action, body = {}) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}` + action, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.getToken()}`
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            return res.result;
        } catch (error) {
            toast.error(error);
            return null;
        }
    }

    async post(action, body = {}) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}` + action, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.getToken()}`
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.isSuccess) {
                toast.success("تمت الإضافة بنجاح");
                return true;
            } else {
                toast.error(JSON.stringify(res.errors));
                return null;
            }
        } catch (error) {
            toast.error(error);
            return null;
        }
    }

    async put(action, body) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}` + action, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.getToken()}`
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.isSuccess) {
                toast.success("تم التعديل بنجاح");
                return res.data;
            } else {
                toast.error(JSON.stringify(res.errors));
                return null;
            }
        } catch (error) {
            toast.error(error);
            return null;
        }
    }
    async delete(action) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}` + action, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.getToken()}`
                },
            });
            const res = await response.json();
            if (res.isSuccess) {
                toast.success("تم الحذف بنجاح");
                return res.data;
            } else {
                toast.error(JSON.stringify(res.errors));
                return null;
            }
        } catch (error) {
            toast.error(error);
            return null;
        }
    }
}

export default new HttpService();