import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MyFunctions from 'functions/myFunctions';
import withAuth from 'functions/withAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import storeManagerService from 'services/storeManagerService';

const StoreManagersList = () => {
    let navigate = useNavigate();
    const gridViewRef = React.useRef(null);

    const refreshGridView = () => {
        gridViewRef.current.refresh();
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyGridView
                ref={gridViewRef}
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_StoreManager/StoreManagersList`}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "fullName",
                        "headerText": "الاسم",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "email",
                        "headerText": "البريد الالكتروني",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "phoneNumber",
                        "headerText": "رقم الهاتف",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "currentBalance",
                        "headerText": "الرصيد",
                        "width": 90,
                        "textAlign": "right"
                    },
                ]}
                allowFiltering={true}
                actionButtons={[
                    {
                        "content": <Icon color='info'>edit</Icon>,
                        "action": (id) => navigate("/storeManagers/" + id)
                    },
                    {
                        "content": <Icon color='error'>delete</Icon>,
                        "action": (id) => {
                            MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف!", () => {
                                storeManagerService.delete(id).then(res => {
                                    refreshGridView();
                                })
                                // customerClassService.delete(data.id).then(res => {
                                //     // getAllCustomersClasses();
                                // });
                            })
                        }
                    },
                ]}
                addAction={() => navigate("/storeManagers/" + 0)}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(StoreManagersList);
