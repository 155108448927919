import { CircularProgress } from '@mui/material';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import preferencesService from 'services/preferencesService';

const Preferences = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState("");
    const [balanceChargeMessage, setBalanceChargeMessage] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    // const [loggFile, setLoggFile] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [waiting, setWaiting] = useState(false);
    var privacyPolicyRef, balanceChargeMessageRef;

    // function handleImgChange(e) {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(e.target.files[0]);

    //     reader.onload = () => {
    //         setLoggFile(reader.result);
    //         setLogoUrl(reader.result)
    //     };
    //     reader.onerror = error => {
    //         console.error("Error: ", error);
    //     };
    // }

    function handleSubmit() {
        const preferences = {
            "privacyPolicy": privacyPolicyRef.value,
            "balanceChargeMessage": balanceChargeMessageRef.value,
            "logoUrl": logoUrl
        }
        setWaiting(true);
        preferencesService.update(preferences).then(() => {
            setWaiting(false);
        })

    }

    useEffect(() => {
        let isMounted = true;

        const fetch = async () => {
            const preferences = await preferencesService.getAll();
            setPrivacyPolicy(preferences.privacyPolicy);
            setBalanceChargeMessage(preferences.balanceChargeMessage);
            setLogoUrl(preferences.logoUrl);
            setIsDataLoaded(true);
        }

        if (isMounted) {
            fetch();
        } else {
            setIsDataLoaded(true);
        }

        return () => {
            isMounted = false;
        };
    }, []);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Row>
                <Col md={6}>
                    <Form.Label>السياسة والخصوصية</Form.Label>
                    <RichTextEditorComponent
                        ref={(richtexteditor) => { privacyPolicyRef = richtexteditor; }}
                        value={privacyPolicy}
                        change={e => setPrivacyPolicy(e.value)}
                        toolbarSettings={{
                            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                                'LowerCase', 'UpperCase', '|',
                                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                                'Outdent', 'Indent', '|',
                                'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                                'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
                        }}>
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                </Col>
                <Col md={6}>
                    <Form.Label>رسالة عند شحن الرصيد</Form.Label>
                    <RichTextEditorComponent
                        ref={(richtexteditor) => { balanceChargeMessageRef = richtexteditor; }}
                        value={balanceChargeMessage}
                        change={e => setBalanceChargeMessage(e.value)}
                        toolbarSettings={{
                            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                                'LowerCase', 'UpperCase', '|',
                                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                                'Outdent', 'Indent', '|',
                                'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                                'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
                        }}>
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                </Col>
                <Col md={6}>
                    <Form.Label>رابط اللوغو</Form.Label>
                    <Form.Control value={logoUrl} onChange={e => setLogoUrl(e.target.value)}></Form.Control>
                    {/* <div style={{ width: "250px", padding: "5px" }}>
                        <img style={{ width: "100px" }} src={logoUrl} alt="Nothing to present" />
                        <MDInput accept="image/*" type="file" size="small" fullWidth onChange={e => handleImgChange(e)} />
                    </div> */}
                </Col>
            </Row>
            <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                {waiting ? (
                    <CircularProgress color="success" />
                ) : (
                    <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                        حفظ
                    </MDButton>
                )}
            </MDBox>
        </DashboardLayout>
    );
}

export default Preferences;
