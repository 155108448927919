import HttpService from '../utility/httpService';
class dynamicPagesService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_DynamicPages/GetAll");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_DynamicPages/getById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_DynamicPages/Add", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put(`/Admin/CMS_DynamicPages/Edit`, data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_DynamicPages/Delete?id=" + id);
        return response;
    }
}

export default new dynamicPagesService();