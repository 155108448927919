import HttpService from '../utility/httpService';
class offersService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_Offers/GetAllOffers");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_Offers/getOfferById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_Offers/AddOffer", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_Offers/EditOffer", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_Offers/DeleteOffer?id=" + id);
        return response;
    }
}

export default new offersService();