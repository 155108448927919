import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MyGridView from 'components/GridView';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MDButton from 'components/MDButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Report = (props) => {
    let navigate = useNavigate();
    const [data, setData] = useState([]);
    const [type, setType] = useState(1);
    const [period, setPeriod] = useState(1);

    // Function to convert a date object to a string in "YYYY-MM-DD" format
    const formatDateToString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());



    function handlePeriodChange(value) {
        let today = new Date();
        let lastDate = new Date();
        setPeriod(value);
        setToDate(today);
        switch (value) {
            case "0": // custom
            case "1": // today
                console.log('today');
                setFromDate(new Date());
                break;
            case "2": // yesterday
                lastDate.setDate(today.getDate() - 1);
                setFromDate(lastDate);
                console.log('fromDate : ', lastDate);
                break;
            case "3": // last week
                lastDate.setDate(today.getDate() - 7);
                setFromDate(lastDate);
                console.log('last week : ', lastDate);
                break;
            case "4": // last month
                lastDate.setDate(today.getDate() - 30);
                setFromDate(lastDate);
                console.log('last mont : ', lastDate);
                break;
            case "5": // last tow months
                lastDate.setDate(today.getDate() - 60);
                setFromDate(lastDate);
                console.log('last tow  : ', lastDate);
                break;
            case "6": // last year
                lastDate.setFullYear(today.getFullYear() - 1);
                setFromDate(lastDate);
                console.log('last year : ', lastDate);
                break;
            default:
                break;
        }

        getData(lastDate, today).then(res => {
            setData([...res.sort((a, b) => b.y - a.y)]);
        });
    }

    async function getData(_fromDate, _toDate) {
        let res = await props.getData(_fromDate.toISOString(), _toDate.toISOString())
        return res;
    }

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                let res = await getData(fromDate, toDate);
                if (isMounted) {
                    setData([...res.sort((a, b) => b.y - a.y)]);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    const chartData = {
        labels: data.map(item => item.x),
        datasets: [
            {
                label: 'المبيعات',
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                data: data.map(item => Number(item.y).toFixed(2)),
            },
            {
                label: 'الكلفة',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                data: data.map(item => Number(item.z).toFixed(2)),
            },
            {
                label: 'profit',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 2,
                data: data.map(item => Number(item.y - item.z).toFixed(2)),
                type: 'line', // Set the type to 'line' to create a line chart
                fill: false, // Set to 'false' if you don't want to fill the area under the line
            },
        ],
    };

    const options = {
        plugins: { // Add the datalabels plugin options
            datalabels: {
                anchor: 'end', // Position of the label relative to the bar
                align: 'top', // Position of the label within the anchor
                formatter: (value) => value, // Use the value itself as the label
                font: {
                    weight: 'bold', // You can customize the font style
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <>
            <Row className='mb-2'>
                <Col md={3}>
                    <Form.Select aria-label="Default select example" value={period} onChange={e => handlePeriodChange(e.target.value)}>
                        <option value="0">مخصص</option>
                        <option value="1">اليوم</option>
                        <option value="2">الأمس</option>
                        <option value="3">آخر 7 أيام</option>
                        <option value="4">آخر 30 يوم</option>
                        <option value="5">آخر 60 يوم</option>
                        <option value="6">آخر سنة</option>
                    </Form.Select>
                </Col>
                <Col md={3}>
                    <Form.Select aria-label="Default select example" value={type} onChange={e => setType(e.target.value)}>
                        <option value="1">جدول</option>
                        <option value="2">مخطط بياني</option>
                    </Form.Select>
                </Col>
                <Col md={2}>
                    <MDButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={() => navigate("/reports")}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </Col>
            </Row>
            {
                period === "0"
                    ?
                    <Row id='customDateContainer' className='mt-2'>
                        <Col md={3}>
                            <Form.Label>من</Form.Label>
                            <Form.Control type='date' value={formatDateToString(fromDate)} onChange={e => setFromDate(e.target.value)} />
                        </Col>
                        <Col md={3}>
                            <Form.Label>إلى</Form.Label>
                            <Form.Control type='date' value={formatDateToString(toDate)} onChange={e => setToDate(e.target.value)} />
                        </Col>
                    </Row>
                    :
                    <></>
            }
            <div id='printReport'>
                {
                    type === '2'
                        ?
                        <Bar data={chartData} options={options} plugins={[ChartDataLabels]} width={400} height={150} />
                        :
                        <MyGridView
                            source={data}
                            columns={props.columns}
                            allowFiltering={true}
                            actionButtons={[]}
                        />
                }
            </div>
        </>
    );
}

export default Report;
