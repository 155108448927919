import { useRef, useState } from 'react';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import "../Category/details.css";
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import supplierService from 'services/supplierService';
import POModel from 'models/POModel';
import MyGridView from 'components/GridView';
import SearchList from 'components/SearchList';
import poService from 'services/poService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import itemStockService from 'services/itemStockService';
import withAuth from 'functions/withAuth';

function PODetails(props) {
    let navigate = useNavigate();
    const { id } = useParams();
    const inputRef = useRef(null);

    // handle default value of today for date field
    var curr = new Date();
    curr.setDate(curr.getDate() + 1);
    var currDate = curr.toISOString().substring(0, 10);
    //------------------------------------------------

    const [poID, setPOID] = useState(0);
    const [poNumber, setPONumber] = useState(0);
    const [supplier, setSupplier] = useState(0);
    const [notes, setNotes] = useState("");
    const [date, setDate] = useState(currDate);
    const [cost, setCost] = useState(0);
    const [suppliersData, setSuppliersData] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsBarCodes, setItemsBarCodes] = useState([]);

    const [itemID, setItemID] = useState(null);
    const [itemSerial, setItemSerial] = useState("");
    const [itemName, setItemName] = useState("");
    const [itemCost, setItemCost] = useState(0);
    const [itemCode, setItemCode] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [waitingItem, setWaitingItem] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    function handlePONumberChange(value) {
        setPONumber(value);
    }

    function handleNotesChange(value) {
        setNotes(value);
    }

    function handleSupplierChange(value) {
        setSupplier(value);
    }

    function handleDateChange(value) {
        setDate(value);
    }

    function handleItemIdChange(value) {
        setItemID(value);
    }

    function handleItemNameChange(value) {
        setItemName(value);
    }

    function handleItemCostChange(value) {
        setItemCost(value);
    }

    function handleItemCodeChange(value) {
        setItemCode(value);
    }

    function handleItemSerialChange(value) {
        setItemSerial(value);
    }

    const handleAddItemCode = (e) => {
        e.preventDefault();
        const regex = /[^0-9,]/g;
        // return regex.test(str);
        if (!itemID ||
            itemCost === 0 ||
            itemCode.trim() === "" ||
            !regex.test() ||
            itemName.trim() === "") {
            return;
        }
        if (itemCode.split(",").length !== itemSerial.split(",").length && itemSerial !== "") {
            toast.error("يجب ان تضع سيريال لكل باركود");
            return;
        }
        var arr = [...itemsBarCodes]
        let totalCost = Number(cost);
        itemCode.split(",").forEach((code, idx) => {
            let codePart = code.split("+")[0];
            let serialPart = code.split("+")[1];
            if (code) {
                let itemBarcode = {
                    "id": 0,
                    "pOmasId": poID,
                    "itemStockId": itemID,
                    "itemStockName": itemName,
                    "cost": codePart,//itemCost,
                    "cardBarcode": code,
                    "serial": serialPart,//itemSerial.split(",")[idx],
                    "expiryDate": "2023-06-11T21:29:37.841Z"
                }
                if (!arr.find(e => e.cardBarcode === code)) {
                    totalCost = totalCost + Number(itemCost);
                    arr.push(itemBarcode)
                } else {
                    toast.error("الباركود موجود");
                    return;
                }
            }
        });
        setCost(totalCost);
        setItemsBarCodes(arr);
        setItemCode("");
        setItemSerial("");
        inputRef.current.focus();
    }

    function onActionComplete(args) {
        if (args.requestType === "delete") {
            let totalCost = Number(cost);
            args.data.forEach(item => {
                totalCost = totalCost - Number(item.cost);
            })
            setCost(totalCost);
        }
    }

    function isNotValidate() {
        if (supplier === 0) {
            toast.error("الرجاء إختيار المورد!");
            return true;
        }
        if (cost === 0) {
            toast.error("الرجاء ملئ تفاصيل الفاتورة بالمواد!");
            return true;
        }
        return false
    }

    function saveAndPost() {
        if (isNotValidate()) {
            return;
        }
        setWaiting(true);
        let po = new POModel(poID, poNumber, supplier, date, cost, notes, true, itemsBarCodes);
        if (Number(id)) {
            poService.update(po).then(res => {
                setWaiting(false);
            })
        } else {
            poService.create(po).then(res => {
                if (res.isSuccess) {
                    navigate("/po")
                }
                setWaiting(false);
            });
        }
    }

    function saveAsDraft() {
        if (isNotValidate()) {
            return;
        }
        setWaiting(true);
        let po = new POModel(poID, poNumber, supplier, date, cost, notes, false, itemsBarCodes);
        if (Number(id)) {
            poService.update(po).then(res => {
                setWaiting(false);
            })
        } else {
            poService.create(po).then(res => {
                setWaiting(false);
                if (res.isSuccess) {
                    navigate("/po")
                }
            });
        }
    }


    useEffect(() => {
        supplierService.getLookups().then(data => {
            setSuppliersData(data);
            itemStockService.getLookups().then(data => {
                setItems(data);
                if (Number(id)) {
                    poService.getById(id).then(po => {
                        var curr = new Date(po.poDate);
                        var currDate = curr.toISOString().substring(0, 10);
                        setPOID(po.id);
                        setPONumber(po.poNumber);
                        setSupplier(po.supplierId);
                        setNotes(po.notes);
                        setDate(currDate);
                        setCost(po.totalCost);
                        setItemsBarCodes(po.pOdtls);
                    })
                    setIsDataLoaded(true);
                } else {
                    setIsDataLoaded(true);
                }
            });
        });

    }, []);

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                <table>
                    <thead></thead>
                    <tbody>
                        {Number(id) ? <tr>
                            <td>
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    رقم الفاتورة </label>
                            </td>
                            <td>
                                <MDInput value={poNumber} size="small" fullWidth onChange={e => handlePONumberChange(e.target.value)} disabled={true} readOnly={true} />
                            </td>
                        </tr> : <></>}

                        <tr>
                            <td>
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    المورد </label>
                            </td>
                            <td>
                                <SearchList
                                    data={suppliersData}
                                    fiels={["name", "id"]}
                                    take={10}
                                    value={supplier}
                                    onChange={(id, name) => handleSupplierChange(id)}
                                />
                            </td>

                            <td>
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    التاريخ </label>
                            </td>
                            <td>
                                <MDInput type="date" value={date} size="small" fullWidth onChange={e => handleDateChange(e.target.value)} />
                            </td>
                            <td>
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    الكلفة </label>
                            </td>
                            <td>
                                <MDInput value={cost} size="small" fullWidth onChange={e => { }} disabled={true} readOnly={true} />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <div style={{ display: "flex", alignItems: "center", width: "700px", justifyContent: "space-between" }}>
                    <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        الملاحظات </label>
                    <textarea style={{ width: "610px", height: "50px" }} value={notes} onChange={e => handleNotesChange(e.target.value)}>
                    </textarea>
                </div>

                <form style={{ display: 'flex', border: '1px solid #d2d6da', alignItems: 'end', padding: '10px', borderRadius: '5px', marginTop: "10px" }}>
                    <div style={{ margin: '0 10px' }}>
                        {waitingItem ? <CircularProgress color="success" /> : <MDButton
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={e => handleAddItemCode(e)}>
                            حفظ
                        </MDButton>}

                    </div>
                    <div style={{ margin: '0 10px' }}>
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            بطاقة المخزون </label>
                        <SearchList
                            data={items}
                            fiels={["name", "id"]}
                            take={10}
                            value={itemID}
                            onChange={(id, name) => {
                                handleItemIdChange(id);
                                handleItemNameChange(name);
                            }}
                        />
                    </div>
                    <div style={{ margin: '0 10px' }}>
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            الكلفة </label>
                        <MDInput value={itemCost} type="number" size="small" fullWidth onChange={e => handleItemCostChange(e.target.value)} />
                    </div>
                    <div style={{ margin: '0 10px' }}>
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            الكود </label>
                        <textarea
                            value={itemCode} ref={inputRef} size="small" fullWidth onChange={e => handleItemCodeChange(e.target.value)}
                        ></textarea>
                        {/* <MDInput value={itemCode} ref={inputRef} size="small" fullWidth onChange={e => handleItemCodeChange(e.target.value)} /> */}
                    </div>
                    <div style={{ margin: '0 10px' }}>
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            السيريال </label>
                        <MDInput value={itemSerial} size="small" fullWidth onChange={e => handleItemSerialChange(e.target.value)} />
                    </div>
                </form>
                <MyGridView
                    source={itemsBarCodes}
                    editSettings={{ allowEditing: false, allowAdding: false, allowDeleting: true }}
                    toolbar={['Delete']}
                    actionComplete={onActionComplete}
                    columns={[
                        {
                            "field": "cardBarcode",
                            "headerText": "الكود",
                            "width": 70,
                            "textAlign": "right",
                            "allowEditing": true
                        },
                        {
                            "field": "itemStockName",
                            "headerText": "المنتج",
                            "width": 70,
                            "textAlign": "right",
                            "allowEditing": false
                        },
                        {
                            "field": "cost",
                            "headerText": "الكلفة",
                            "width": 70,
                            "textAlign": "right",
                            "allowEditing": true
                        },
                        {
                            "field": "serial",
                            "headerText": "السيريال",
                            "width": 70,
                            "textAlign": "right",
                            "allowEditing": true
                        },
                    ]}
                    actionButtons={[]}
                    preventPagination={true}
                    addAction={null}
                />
                <div style={{ display: "flex" }}>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        {waiting ? <CircularProgress color="success" /> : <MDButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={saveAsDraft}>
                            حفظ كمسودة
                        </MDButton>}
                    </MDBox>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        {waiting ? <CircularProgress color="success" /> : <MDButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={saveAndPost}>
                            حفظ وترحيل
                        </MDButton>}
                    </MDBox>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        <MDButton
                            variant="gradient"
                            color="primary"
                            fullWidth
                            onClick={() => navigate("/po")}>
                            الرجوع إلى القائمة
                            <ArrowBackIcon style={{ margin: "0 10px" }} />
                        </MDButton>
                    </MDBox>
                </div>

                <Footer />
            </div>
        </DashboardLayout >
    );
}

export default withAuth(PODetails);