import HttpService from '../utility/httpService';
class newsService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_News/GetAllNews");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_News/getNewsById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_News/AddNews", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_News/UpdateNews", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_News/DeleteNews?id=" + id);
        return response;
    }
}

export default new newsService();