import HttpService from '../utility/httpService';
class storeManagerService {

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_StoreManager/GetStoreManagerById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_StoreManager/AddStoreManager", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_StoreManager/UpdateStoreManager", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_StoreManager/DeleteStoreManager?id=" + id);
        return response;
    }

    async getAllCountries() {
        const response = await HttpService.get("/Admin/CMS_Lookups/Countries");
        return response;
    }

    async getAllCities() {
        const response = await HttpService.get("/Admin/CMS_Lookups/Cities");
        return response;
    }
}

export default new storeManagerService();