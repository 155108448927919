import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import './index.css'
import { useEffect } from "react";
import adminService from "services/adminService";
const RoleCheckboxList = ({ onRolesChange, checkedRolesArray }) => {
    const [checkedRoles, setCheckedRoles] = useState(checkedRolesArray);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const fetch = async () => {
            const allRoles = await adminService.getRoles();
            setRoles([...allRoles])
        }

        if (isMounted) {
            fetch();
        }
        return () => {
            isMounted = false;
        };
    }, []);

    const handleCheckChange = (event, id) => {
        if (event.target.checked) {
            setCheckedRoles(prevState => {
                const updatedRoles = [...prevState, id];
                onRolesChange(updatedRoles);
                return updatedRoles;
            });
        } else {
            setCheckedRoles(prevState => {
                const updatedRoles = prevState.filter(roleId => roleId !== id);
                onRolesChange(updatedRoles);
                return updatedRoles;
            });
        }
    };

    return (
        <div className="rolesWrapper">
            {roles.map((group, index) => (
                <Card className="mb-4" border="primary" key={index} style={{ width: "300px" }}>
                    <Card.Body>
                        <Card.Title style={{ borderBottom: "1px solid gray", textAlign: "center" }}>{group.groupName}</Card.Title>
                        {group.roles.map(role => (
                            <Form.Check
                                className="custom-control"
                                type="checkbox"
                                id={role.id}
                                checked={checkedRoles.includes(role.id)}
                                label={role.name}
                                onChange={event => handleCheckChange(event, role.id)}
                            />
                        ))}
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
};

export default RoleCheckboxList;