import { Icon } from "@mui/material";
import MyTreeGrid from "components/TreeGrid";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MyFunctions from "functions/myFunctions";
import withAuth from "functions/withAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import categoryService from "services/categoryService";
function CatigoriesList() {
    const [categories, setCategories] = useState([]);
    let navigate = useNavigate();

    function getallCategories() {
        categoryService.getAll().then(data => {
            setCategories([...data])
        })
    }
    useEffect(() => {
        getallCategories()
    }, []);




    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyTreeGrid
                source={categories}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "catNameWithParent",
                        "headerText": "الاسم",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "categoryTypeName",
                        "headerText": "نوع العائلة",
                        "width": 90,
                        "textAlign": "right"
                    }
                ]}
                actionButtons={[
                    {
                        "content": <Icon color="success">add</Icon>,
                        "action": (data) => {
                            data.type = "add";
                            return navigate("/categories/details", { state: { data: JSON.stringify(data), type: "add" } })
                        }
                    },
                    {
                        "content": <Icon color="info">edit</Icon>,
                        "action": (data) => {
                            data.type = "edit";
                            return navigate("/categories/details", { state: { data: JSON.stringify(data) } });
                        }
                    },
                    {
                        "content": <Icon color="error">delete</Icon>,
                        "action": (data) => {
                            MyFunctions.showConfirmationMessage("عملية حذف", "هل أنت متأكد من الحذف", () => {
                                categoryService.deleteCategory(data.item.id).then(res => {
                                    getallCategories();
                                })
                            })

                        }
                    },
                ]}
                addAction={(categories) => {
                    let data = {};
                    data.type = "addMaster";
                    return navigate("/categories/details", { state: { data: JSON.stringify(data) } });
                }}
            />

            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(CatigoriesList);