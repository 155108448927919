import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import withAuth from 'functions/withAuth';
import React, { useState } from 'react';

const BalanceRequestList = () => {
    const [maximizedImage, setMaximizedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setMaximizedImage(imageUrl);
    };

    const handleCloseMaximizedImage = () => {
        setMaximizedImage(null);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                {maximizedImage && (
                    <div className="maximized-image-overlay" onClick={handleCloseMaximizedImage}>
                        <img className="maximized-image" src={maximizedImage} alt="Maximized Image" />
                    </div>
                )}
                <MyGridView
                    url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_BalanceCharge/BalanceRequestList`}
                    columns={[
                        {
                            field: "userName",
                            headerText: "الاسم",
                            width: 70,
                            textAlign: "right"
                        },
                        {
                            field: "requestDate",
                            headerText: "تاريخ الطلب",
                            width: 90,
                            textAlign: "right",
                            type: "date",
                            format: "dd/MM/yyyy",
                        },
                        {
                            field: "requestedBalance",
                            headerText: "الرصيد المطلوب",
                            width: 90,
                            textAlign: "right",
                            type: "number",
                            format: "n3",
                        },
                        {
                            field: "imageUrl",
                            headerText: "",
                            width: 70,
                            textAlign: "right",
                            template: (rowData) => {
                                return (
                                    <img
                                        src={rowData.imageUrl}
                                        alt="Image"
                                        style={{ height: "100px", width: "100px", cursor: "pointer" }}
                                        onClick={() => handleImageClick(rowData.imageUrl)}
                                    />
                                );
                            }
                        },
                    ]}
                    allowFiltering={true}
                    actionButtons={[]}
                />
            </div>
            <Footer />
        </DashboardLayout>
    );
};

export default withAuth(BalanceRequestList);
