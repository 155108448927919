import { CircularProgress, Switch } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import adminService from 'services/adminService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import withAuth from 'functions/withAuth';
import ManageBalance from 'components/ManageBalance';
import auth from 'services/auth';
import RoleCheckboxList from './components/roles';

const UserDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [currentBalance, setCurrentBalance] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [checkedRoles, setCheckedRoles] = useState([]);

    const isEditMode = Number(id);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const res = await adminService.getById(id)
                if (isMounted) {
                    // Update state only if the component is still mounted
                    setFirstName(res.firstName);
                    setLastName(res.lastName);
                    setEmail(res.email);
                    setPassword(res.password);
                    setPhoneNumber(res.phoneNumber);
                    setCurrentBalance(res.currentBalance);
                    setIsActive(res.isActive);
                    setCheckedRoles(res.roles);
                    setIsDataLoaded(true);
                } else {
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        if (isEditMode) {
            fetchData();
        } else {
            setIsDataLoaded(true);
        }

        return () => {
            // Cleanup function
            isMounted = false;
            // Cancel any ongoing asynchronous tasks or subscriptions here
            // ...
        };
    }, [isEditMode, id]);

    const validatePassword = (password) => {
        // Minimum length of 8 characters
        if (password.length < 8) {
            return 'Password must be at least 8 characters long.';
        }

        // At least 3 letters
        const letterRegex = /[a-zA-Z]/g;
        const letterMatches = password.match(letterRegex);
        if (!letterMatches || letterMatches.length < 3) {
            return 'Password must contain at least 3 letters.';
        }

        // At least 1 uppercase letter
        const uppercaseRegex = /[A-Z]/g;
        const uppercaseMatches = password.match(uppercaseRegex);
        if (!uppercaseMatches || uppercaseMatches.length < 1) {
            return 'Password must contain at least 1 uppercase letter.';
        }

        // At least 3 numeric characters
        const numericRegex = /[0-9]/g;
        const numericMatches = password.match(numericRegex);
        if (!numericMatches || numericMatches.length < 3) {
            return 'Password must contain at least 3 numeric characters.';
        }

        // At least 1 special character
        const specialRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
        const specialMatches = password.match(specialRegex);
        if (!specialMatches || specialMatches.length < 1) {
            return 'Password must contain at least 1 special character.';
        }

        // Password meets all criteria
        return null;
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPasswordError(validatePassword(newPassword));
    };

    function activateAccount() {
        if (auth.activateAccount(id)) {
            setIsActive(true);
            toast.success("تم التفعيل بنجاح");
        } else {
            toast.error("حطأ في عملية تفعيل الحساب");
        }
    }
    function deActivateAccount() {
        if (auth.deActivateAccount(id)) {
            setIsActive(false);
            toast.success("تم إلغاء التفعيل بنجاح");
        } else {
            toast.error("حطأ في عملية إلغاء تفعيل الحساب");
        }
    }

    const handleSubmit = () => {
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        setWaiting(true);

        const adminData = {
            email,
            phoneNumber,
            firstName,
            lastName,
            isActive,
            "roles": checkedRoles
        };

        if (isEditMode) {
            adminData.id = id;
            adminService.update(adminData).then(() => {
                setWaiting(false);
            });
        } else {
            adminData.password = password;
            adminService.create(adminData).then(res => {
                if (res) {
                    navigate('/users');
                }
                setWaiting(false);
            });
        }
    };

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                isEditMode
                    ?
                    <ManageBalance
                        name={firstName + " " + lastName}
                        userId={id}
                    />
                    :
                    <></>
            }

            <table>
                <thead></thead>
                <tbody>
                    {
                        isEditMode
                            ?
                            <tr>
                                <td>
                                    <p className="control-label" style={{ textAlign: 'left', fontSize: '16px', fontWeight: 'bold', color: currentBalance < 0 ? 'red' : 'green' }}>
                                        الرصيد الحالي : {currentBalance}</p>
                                </td>
                            </tr>
                            :
                            <></>
                    }
                    <tr>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                الاسم الأول</label>
                        </td>
                        <td>
                            <MDInput value={firstName} size="small" fullWidth onChange={e => setFirstName(e.target.value)} />
                        </td>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                الاسم الثاني</label>
                        </td>
                        <td>
                            <MDInput value={lastName} size="small" fullWidth onChange={e => setLastName(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                البريد الإلكتروني</label>
                        </td>
                        <td>
                            <MDInput value={email} size="small" fullWidth onChange={e => setEmail(e.target.value)} />
                        </td>
                        {!isEditMode && (
                            <>
                                <td>
                                    <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                        كلمة المرور</label>
                                </td>
                                <td>
                                    <MDInput
                                        value={password}
                                        size="small"
                                        fullWidth
                                        onChange={handlePasswordChange}
                                    />
                                </td>
                            </>
                        )}
                        <td>
                            <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                رقم الهاتف</label>
                        </td>
                        <td>
                            <MDInput value={phoneNumber} size="small" fullWidth onChange={e => setPhoneNumber(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={() => { }}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    تفعيل الحساب </label>
                            </MDTypography>
                        </td>
                        <td>
                            <MDBox display="flex" alignItems="center" ml={-1}>
                                <Switch checked={isActive} disabled={true} onChange={() => setIsActive(!isActive)} />
                            </MDBox>
                        </td>
                        <td>
                            {
                                isActive
                                    ?
                                    <MDButton variant="gradient" color="error" fullWidth onClick={deActivateAccount}>
                                        إلغاء تفعيل الحساب
                                    </MDButton>
                                    :
                                    <MDButton variant="gradient" color="success" fullWidth onClick={activateAccount}>
                                        تفعيل الحساب
                                    </MDButton>

                            }
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
            <RoleCheckboxList onRolesChange={(result) => { setCheckedRoles([...result]) }} checkedRolesArray={checkedRoles} />
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    {waiting ? (
                        <CircularProgress color="success" />
                    ) : (
                        <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                            حفظ
                        </MDButton>
                    )}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton variant="gradient" color="primary" fullWidth onClick={() => navigate('/users')}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
        </DashboardLayout>
    );
};

export default withAuth(UserDetails);
