import HttpService from '../utility/httpService';
class balanceService {
    async addBalance(userId, amount) {
        const response = await HttpService.post("/Admin/CMS_BalanceCharge/BalanceCharge",
            {
                userId: userId,
                amount: amount
            });
        return response;
    }

    async withdrawBalance(userId, amount) {
        const response = await HttpService.post(`/Admin/CMS_BalanceCharge/BalanceWithdraw?userId=${userId}&amt=${amount}`);
        return response;
    }
}

export default new balanceService();