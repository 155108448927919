import HttpService from '../utility/httpService';
class extraFieldsService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_ExtraField/GetAllExtraFields");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_ExtraField/getById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_ExtraField/AddExtraField", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put(`/Admin/CMS_ExtraField/EditExtraField`, data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_ExtraField/DeleteExtraField?id=" + id);
        return response;
    }
}

export default new extraFieldsService();