import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import withAuth from 'functions/withAuth';
import React from 'react';

const NotificationsList = () => {
    const gridViewRef = React.useRef(null);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyGridView
                url={`${process.env.REACT_APP_BASE_URL}/Admin/CMS_Users/GetAdminNotifications`}
                ref={gridViewRef}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "title",
                        "headerText": "العنوان",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "body",
                        "headerText": "التفاصيل",
                        "width": 90,
                        "textAlign": "right"
                    },
                    {
                        "field": "date",
                        "headerText": "التاريخ",
                        "width": 90,
                        "textAlign": "right",
                        "format": "dd/MM/yyyy",
                        "type": "date"
                    },
                ]}
                allowFiltering={true}
                actionButtons={[]}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(NotificationsList);
