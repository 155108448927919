import { useState } from 'react';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import "../Category/details.css";
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import categoryService from 'services/categoryService';
import { CircularProgress, Switch } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import withAuth from 'functions/withAuth';
import { Col, Form, Row } from 'react-bootstrap';
import SearchList from 'components/SearchList';
function CategoryDetails(props) {
    let navigate = useNavigate();
    const locations = useLocation();
    var locationData = JSON.parse(locations.state.data);
    var categories = locationData.data ? locationData.data : [];
    var item = locationData.item;
    var type = locationData.type;
    if (type === "add") {
        item.parentId = item.id;
        item.catName = "";
        item.description = "";
        item.isActive = true;
        item.id = 0;
        item.categoryType = 0;
        item.img = "string";
        item.imgFile = "string";
    }
    const [name, setName] = useState(item ? item.catName : "");
    const [parent, setParent] = useState(item ? item.parentId : null);
    const [img, setImg] = useState(item ? item.img : "");
    const [imgFile, setImgFile] = useState(null);
    const [categoryType, setCategoryType] = useState(item ? item.categoryType : 0);
    const [isActive, setIsActive] = useState(item ? item.isActive : true);
    const [waiting, setWaiting] = useState(false);

    function handleIsActiveChange(value) {
        setIsActive(value);
    }

    function handleImgChange(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImgFile(reader.result);
            setImg(reader.result)
        };
        reader.onerror = error => {
            console.error("Error: ", error);
        };
    }

    function handleSubmit() {
        let newCategory = {
            "id": type === "edit" ? item.id : 0,
            "catName": name,
            "parentId": parent,
            "isActive": isActive,
            "categoryType": categoryType,
            "img": img,
            "imgFile": imgFile,
        }
        if (String(newCategory.catName).trim() === "" || !newCategory.catName) {
            toast.error("اسم العائلة مطلوبة");
            return;
        }
        setWaiting(true);
        if (type === "edit") {
            categoryService.updateCategory(newCategory).then(res => {
                setWaiting(false);
            })
        } else {
            categoryService.createCategory(newCategory).then(res => {
                if (res) {
                    navigate("/categories")
                } else {
                    setWaiting(false);
                }
            })
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                {
                    item
                        ?
                        <Form.Text>العائلة الأم : {categories.find(e => e.id === item.parentId) ? categories.find(e => e.id === item.parentId).catName : "لا يوجد"}</Form.Text>
                        :
                        <></>
                }
                <Row className='mt-2'>
                    <Col md={2}>
                        <Form.Label>الاسم</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)}></Form.Control>
                    </Col>
                    <Col md={2}>
                        <Form.Label>النوع</Form.Label>
                        <SearchList
                            data={[{ id: 0, name: "شحن" }, { id: 1, name: "بطاقة" }]}
                            value={categoryType}
                            type="text"
                            size="small"
                            fullWidth
                            onChange={(id, name) => {
                                setCategoryType(id);
                            }} />
                    </Col>
                    <Col md={2}>
                        <div style={{ width: "250px", padding: "5px" }}>
                            <img style={{ width: "100px" }} src={img} alt="Nothing to present" />
                            <MDInput accept="image/*" type="file" size="small" fullWidth onChange={e => handleImgChange(e)} />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col md={3}>
                        <Form.Label>فعالة</Form.Label>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                            <Switch checked={isActive} onChange={() => handleIsActiveChange(!isActive)} />
                        </MDBox>
                    </Col>
                </Row>
                <div style={{ display: "flex" }}>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        {waiting ? <CircularProgress color="success" /> : <MDButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={handleSubmit}>
                            حفظ
                        </MDButton>}
                    </MDBox>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        <MDButton
                            variant="gradient"
                            color="primary"
                            fullWidth
                            onClick={() => navigate("/categories")}>
                            الرجوع إلى القائمة
                            <ArrowBackIcon style={{ margin: "0 10px" }} />
                        </MDButton>
                    </MDBox>
                </div>
            </div>
            <Footer />
        </DashboardLayout >
    );
}

export default withAuth(CategoryDetails);