import swal from 'sweetalert';

class MyFunctions {
    static showConfirmationMessage(
        title,
        text,
        yesAction = () => { },
        noAction = () => { },
        icon = "warning",
        buttons = {
            cancel: true,
            confirm: true,
        }) {
        swal({
            title: title,//"Confirm",
            text: text,//"Are you sure you want to do this?",
            icon: "warning",
            buttons: buttons
        })
            .then((willConfirm) => {
                if (willConfirm) {
                    yesAction();
                    // swal("Success", "Action completed successfully!", "success");
                } else {
                    noAction();
                    swal("تم إلفاء العملية");
                }
            });
    }
}
export default MyFunctions;