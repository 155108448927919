import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/auth';

const withAuth = (WrappedComponent) => {
    const ValidatedComponent = (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            const validateToken = async () => {
                const token = authService.getToken(); // Replace this with your token retrieval logic

                if (!token) {
                    // Redirect to login or unauthorized page if token is not present
                    navigate('/'); // Replace with your desired route
                } else {
                    const isValid = await authService.validateToken(token); // Replace with your token validation logic
                    if (!isValid) {
                        // Redirect to login or unauthorized page if token is invalid
                        navigate('/'); // Replace with your desired route
                    }
                }
            };

            validateToken();
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };

    return ValidatedComponent;
};

export default withAuth;