import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from "react-toastify";
import itemStockService from "services/itemStockService";
import { useEffect } from "react";
import withAuth from "functions/withAuth";

function StockItemDetails(params) {
    let navigate = useNavigate();
    const { id } = useParams();

    const [name, setName] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const isEditMode = Number(id);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {

                if (isEditMode) {
                    const item = await itemStockService.getById(id)
                    if (isMounted) {
                        // Update state only if the component is still mounted
                        setName(item.name);
                        setIsDataLoaded(true);
                    } else {
                        setIsDataLoaded(true);
                    }
                } else {
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        return () => {
            // Cleanup function
            isMounted = false;
            // Cancel any ongoing asynchronous tasks or subscriptions here
            // ...
        };
    }, [isEditMode, id]);

    function handleNameChange(value) {
        setName(value);
    }

    function handleSubmit() {
        let newCustomerClass = {
            "id": id,
            "name": name,
            "availableQty": 0,
            "totalQty": 0
        };
        if (String(name).trim() === "" || !name) {
            toast.error("لايمكن ان يكون الأسم فارغا");
            return;
        }
        setWaiting(true);
        if (isEditMode) {
            itemStockService.update(newCustomerClass).then(res => {
                setWaiting(false);
            })
        } else {
            itemStockService.create(newCustomerClass).then(res => {
                setWaiting(false);
            })
        }
    }

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ backgroundColor: "white" }}>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>
                                <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                    الاسم </label>
                            </td>
                            <td>
                                <MDInput value={name} size="small" fullWidth onChange={e => handleNameChange(e.target.value)} />
                            </td>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <div style={{ display: "flex" }}>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        {waiting ? <CircularProgress color="success" /> : <MDButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={handleSubmit}>
                            حفظ
                        </MDButton>}
                    </MDBox>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        <MDButton
                            variant="gradient"
                            color="primary"
                            fullWidth
                            onClick={() => navigate("/stockItems")}>
                            الرجوع إلى القائمة
                            <ArrowBackIcon style={{ margin: "0 10px" }} />
                        </MDButton>
                    </MDBox>
                </div>
            </div>

            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(StockItemDetails);