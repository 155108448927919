import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import soService from 'services/soService';
import './details.css';
import MyFunctions from 'functions/myFunctions';
import SupplyerModal from './components/supplyerModal';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import withAuth from 'functions/withAuth';
import { Button, Col, Form, Row } from 'react-bootstrap';

const SoDetails = () => {
    let navigate = useNavigate();
    const { id } = useParams();

    const [soDate, setSoDate] = useState("");
    const [soDtlId, setSoDtlId] = useState(0);
    const [customer, setCustomer] = useState("");
    const [balanceBefore, setBalanceBefore] = useState(0);
    const [balanceAfter, setBalanceAfter] = useState(0);
    const [acceptDate, setAcceptDate] = useState("");
    const [admin, setAdmin] = useState("");
    const [customerNotes, setCustomerNotes] = useState("");
    const [adminNotes, setadminNotes] = useState("");
    const [soDetails, setSoDetails] = useState([]);
    const [soStatus, setSoStatus] = useState(0);
    const [statusName, setStatusName] = useState("");
    const [finishDate, setFinishDate] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [soDtls, setSoDtls] = useState(0);
    const [maximizedImage, setMaximizedImage] = useState(false);

    function getSoData() {
        soService.getById(id).then(res => {
            console.log('res : ', res);
            setSoDtlId(res.id);
            setSoDate(res.soDate);
            setAcceptDate(res.acceptDate);
            setCustomer(res.customerName);
            setBalanceBefore(res.customerOldBalance);
            setBalanceAfter(res.customerNewBalance);
            setAdmin(res.processedByUserName);
            setCustomerNotes(res.customerNotes);
            setSoDetails(res.soDtls);
            setSoStatus(res.soStatus);
            setStatusName(res.statusName);
            setFinishDate(res.finishDate);
            setadminNotes(res.adminNotes)
        })
    }
    var adminNotesRef;

    useEffect(() => {
        getSoData();
    }, []);

    const handleImageClick = () => {
        setMaximizedImage(!maximizedImage);
    };

    function acceptSo() {
        MyFunctions.showConfirmationMessage("قبول الطلبية", "هل أنت متأكد من قبول الطلبية!", () => {
            soService.acceptSo(id).then(res => {
                if (res) {
                    getSoData();
                }
            })
        })

    }

    function rejectSo() {
        MyFunctions.showConfirmationMessage("رفض الطلبية", "هل أنت متأكد من رفض الطلبية!", () => {
            soService.rejectSo(id).then(res => {
                if (res) {
                    getSoData();
                }
            })
        })

    }

    function completeSo() {
        MyFunctions.showConfirmationMessage("إتمام الطلبية", "هل أنت متأكد من إتمام الطلبية!", () => {
            soService.completeSo(id).then(res => {
                if (res) {
                    getSoData();
                }
            });
        })
    }

    function rejectItem(id) {
        MyFunctions.showConfirmationMessage("رفض المنتج", "هل أنت متأكد من رفض المنتج!", () => {
            soService.rejectSoItem(id).then(res => {
                if (res) {
                    getSoData();
                }
            });
        })
    }

    function getFromInventory(id) {
        MyFunctions.showConfirmationMessage("جلب من المخزن", "هل أنت متأكد من جلب الأكواد من المخزن ؟", () => {
            soService.getITemFromTheInventory(id).then(res => {
                if (res) {
                    getSoData();
                }
            });
        })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="_container">
                <div className='buttonsContainer'>
                    {soStatus === 1
                        ?
                        <MDButton
                            variant="gradient"
                            color="success"
                            size="large"
                            onClick={completeSo}>
                            إكمال الطلبية
                        </MDButton>
                        :
                        <></>
                    }
                    {soStatus === 0
                        ?
                        <MDButton
                            variant="gradient"
                            color="error"
                            size="large"
                            onClick={rejectSo}>
                            رفض الطلبية
                        </MDButton>
                        :
                        <></>
                    }
                    {soStatus === 0
                        ?
                        <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            onClick={acceptSo}>
                            قبول الطلبية
                        </MDButton>
                        :
                        <></>
                    }
                    <MDButton
                        variant="gradient"
                        color="warning"
                        size="large"
                        onClick={() => navigate("/so")}>
                        رجوع للقائمة
                    </MDButton>
                </div>
                <Row>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>حالة الطلبية:</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Text style={{ color: 'green' }}>{statusName}</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Text>{new Date(finishDate).toLocaleTimeString("en-GB")} : {new Date(finishDate).toLocaleDateString("en-GB")}</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', fontWeight: 400 }}>التاريخ والوقت</Form.Label>
                            <Form.Control type="text" value={new Date(soDate).toLocaleDateString("en-GB")} size="sm" onChange={e => { }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', fontWeight: 400 }}>العميل</Form.Label>
                            <Form.Control type="text" value={customer} size="sm" onChange={e => { }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', fontWeight: 400 }}>رصيد العميل قبل</Form.Label>
                            <Form.Control type="text" value={balanceBefore} size="sm" onChange={e => { }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', fontWeight: 400 }}>رصيد العميل بعد</Form.Label>
                            <Form.Control type="text" value={balanceAfter} size="sm" onChange={e => { }} />
                        </Form.Group>
                    </Col>
                </Row>
                {
                    (soStatus !== 0 && soStatus !== 3)
                        ?
                        <>
                            <Row>
                                <Col md={3}>
                                    <Form.Label style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                        تاريخ المعالجة
                                    </Form.Label>
                                    <Form.Control type="text" value={new Date(acceptDate).toLocaleDateString("en-GB")} size="sm" onChange={e => { }} />
                                </Col>
                                <Col md={3}>
                                    <Form.Label style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                                        المسؤول
                                    </Form.Label>
                                    <Form.Control type="text" value={admin} size="sm" onChange={e => { }} />
                                </Col>
                            </Row>
                        </>
                        :
                        <></>
                }
                <div>
                    <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        ملاحظات العميل</label>
                    <RichTextEditorComponent value={customerNotes}>
                        <></>
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                    </RichTextEditorComponent>
                </div>
                <div className="_container">
                    <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        ملاحظات المدير</label>
                    <RichTextEditorComponent
                        ref={(richtexteditor) => { adminNotesRef = richtexteditor; }}
                        change={e => setadminNotes(e.value)}
                        value={adminNotes}>
                        <></>
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                    </RichTextEditorComponent>
                    <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                        <MDButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            onClick={() => {
                                MyFunctions.showConfirmationMessage("حفظ الملاحظات", "هل أنت متأكد حفظ الملاحظات!", () => {
                                    const htmlContent = adminNotesRef.value;
                                    const parser = new DOMParser();
                                    const parsedHtml = parser.parseFromString(htmlContent, 'text/html');
                                    const textContent = parsedHtml.body.textContent;
                                    soService.saveAdminNotes(soDtlId, textContent);
                                })
                            }}>
                            حفظ
                        </MDButton>
                    </MDBox>
                </div>
                {
                    (soStatus !== 0 && soStatus !== 3) ?
                        <div>
                            <div className='barcodeListContainer'>
                                <div className='barcodeListHeader'>
                                    <p>المجموع : {soDetails?.reduce((accumulator, obj) => accumulator + obj.itemPrice, 0)}</p>
                                    <p> عدد الأصناف : {soDetails ? soDetails.length : 0}</p>
                                </div>
                                <div className='barcodeListBody'>
                                    {soDetails?.map((item, idx) => {
                                        return (
                                            <div key={idx} className='itemContainer'>

                                                <div className='itemHeader'>
                                                    <p>المنتج : {item.itemName}</p>
                                                    <p> السعر : {item.itemPrice}</p>
                                                    <p>الكمية : {item.qty}</p>
                                                </div>
                                                <div className='itemBody'>
                                                    <Row className='m-2'>
                                                        {
                                                            item.extraFields.map(ef => (
                                                                <Col md={4}>
                                                                    {
                                                                        Number(ef.fieldType) === 2
                                                                            ?
                                                                            <Button onClick={handleImageClick}>
                                                                                {ef.extraFieldName}
                                                                                {maximizedImage ? (
                                                                                    <img className="img-fluid" src={ef.extraFieldValue} alt="Maximized img" />
                                                                                ) :
                                                                                    <img
                                                                                        src={ef.extraFieldValue}
                                                                                        alt="Nothing"
                                                                                        style={{ height: "0", width: "0", cursor: "pointer" }}
                                                                                    />}
                                                                            </Button>
                                                                            :
                                                                            <Form.Label>{ef.extraFieldName} : {ef.extraFieldValue}</Form.Label>
                                                                    }
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                    {
                                                        item.itemChargeType === 0
                                                            ?
                                                            <>
                                                                {item.sellType === 1
                                                                    ?
                                                                    <div className='buttonsContainer'>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            size="large"
                                                                            onClick={() => {
                                                                                window.open('http://localhost:3000/po/0', '_blank');
                                                                            }}>
                                                                            ادخال فاتورة شراء
                                                                        </MDButton>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="secondary"
                                                                            size="large"
                                                                            onClick={() => { getFromInventory(item.id) }}>
                                                                            من مخزن
                                                                        </MDButton>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="error"
                                                                            size="large"
                                                                            onClick={() => { rejectItem(item.id) }}>
                                                                            رفض
                                                                        </MDButton>
                                                                    </div>
                                                                    :
                                                                    <div className='buttonsContainer'>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            size="large"
                                                                            onClick={() => {
                                                                                console.log('item.id : ', item.id);
                                                                                setSoDtls(item.id)
                                                                                setModalIsOpen(true);
                                                                            }}>
                                                                            من مورد
                                                                        </MDButton>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="secondary"
                                                                            size="large"
                                                                            onClick={() => { getFromInventory(item.id) }}>
                                                                            من مخزن
                                                                        </MDButton>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="error"
                                                                            size="large"
                                                                            onClick={() => { rejectItem(item.id) }}>
                                                                            رفض
                                                                        </MDButton>
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            item.itemChargeType === 1 || item.itemChargeType === 2
                                                                ?
                                                                item.barcodes?.map((barcode, codeIdx) => {
                                                                    return (
                                                                        <div key={codeIdx} className='barcodeCard'>
                                                                            <p>الباركود : {barcode.barcode}</p>
                                                                            <p>السيريال : {barcode.serial}</p>
                                                                            <p>تاريخ الانتهاء : {barcode.endDate ? new Date(barcode.endDate).toLocaleDateString("en-GB") : ''}</p>
                                                                            <p>الكلفة : {barcode.cost}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                item.itemChargeType === 3
                                                                    ?
                                                                    <div className='barcodeCard'>
                                                                        <p>شحن مباشر من مورد : {item.supplierName}</p>
                                                                        <p>الكلفة : {item.itemCost}</p>
                                                                        <p>ملاحظات : {item.notes}</p>
                                                                    </div>
                                                                    :
                                                                    item.itemChargeType === 4
                                                                        ?
                                                                        <div className='barcodeCard'>
                                                                            <p style={{ color: "red" }}>تم رفض المنتج</p>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                        :
                        <></>
                }
            </div>
            <SupplyerModal
                isOpen={modalIsOpen}
                soDtls={soDtls}
                onClose={() => { setModalIsOpen(false) }}
                cb={() => {
                    setModalIsOpen(false);
                    getSoData();
                }}
            />
        </DashboardLayout>
    );
}

export default withAuth(SoDetails);
