import { Icon, IconButton } from '@mui/material';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Inject, Edit, Toolbar, Filter, ExcelExport } from '@syncfusion/ej2-react-grids';
import MDBox from 'components/MDBox';
import { navbarIconButton } from 'examples/Navbars/DashboardNavbar/styles';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import React from "react";
import auth from 'services/auth';

function MyGridView(props, ref) {
	const gridRef = React.useRef(null);

	React.useImperativeHandle(ref, () => ({
		refresh: () => {
			gridRef.current.refresh();
		}
	}));

	var allData = []
	var data2 = new DataManager({
		adaptor: new UrlAdaptor(),
		url: props.url,
		crossDomain: true,
		headers: [{ 'Authorization': `Bearer ${auth.getToken()}` }],
	});

	function getColumns() {
		var columns = [];
		if (props.columns?.length > 0) {
			columns = props.columns;
		} else {
			if (props.source) {
				columns = Object.keys(props.source[0]).map(key => {
					return {
						field: key,
						headerText: key,
						width: 90,
						textAlign: "right",
					}
				});
			}
		}
		return columns.map((column, idx) => {
			return (
				<ColumnDirective
					key={idx}
					field={column.field}
					headerText={column.headerText}
					width={column.width}
					textAlign={column.textAlign}
					allowEditing={column.allowEditing}
					displayAsCheckBox={column.displayAsCheckBox}
					editType={column.editType}
					type={column.type}
					format={column.format}
					clipMode={column.clipMode}
					template={column.template}
					filterTemplate={column.filterTemplate}
					filter={{ operator: 'contains' }}
				></ColumnDirective>
			)
		})
	}

	function getActionButtons(data) {
		allData.push(data)
		var buttons = props.actionButtons?.length === 0 ? [] : props.actionButtons;
		return buttons.map((button, idx) => {
			return (
				<IconButton key={idx} sx={navbarIconButton} size="small" disableRipple onClick={() => {
					button.action(data.id);
				}}>
					{button.content}
				</IconButton>
			)
		})
	}

	function getServices() {
		var services = [Edit, Toolbar, Filter, Sort, ExcelExport]; //[Page, Edit, Toolbar, Filter, Sort]
		if (!props.preventPagination) {
			services.push(Page);
		}
		return services;

	}
	const toolbarClick = (args) => {
		if (gridRef && args.item.id === 'grid_excelexport') {
			gridRef.current.excelExport();
		}
	};

	function getToolBar(){
		if(props.toolbar){
			props.toolbar.push('ExcelExport');
			return props.toolbar;
		}else{
			return ['ExcelExport']
		}
	}
	return (
		<div className='control-pane'>
			<div className='control-section'>
				{
					props.addAction ?
						<MDBox
							display="flex"
							justifyContent="center"
							alignItems="center"
							width="3.25rem"
							height="3.25rem"
							bgColor="white"
							shadow="sm"
							borderRadius="50%"
							margin="5px"
							right="2rem"
							bottom="2rem"
							zIndex={99}
							color="white"
							backgroundColor="green !important"
							sx={{ cursor: "pointer" }}
							onClick={() => props.addAction(allData)}
						>
							<Icon fontSize="small" color="inherit">
								add
							</Icon>
						</MDBox> :
						null
				}
				<GridComponent
					// toolbarClick={toolbarClick}
					id='grid'
					ref={gridRef}
					staticHeader={true}
					allowResizing={false}
					dataSource={props.source ? props.source : data2}
					allowSorting={true}
					allowPaging={true}
					allowExcelExport={true}
					allowFiltering={props.allowFiltering}
					filterSettings={{
						type: 'Menu',
						ignoreAccent: false
					}}
					enableRtl={false}
					editSettings={props.editSettings}
					toolbar={getToolBar()}
					toolbarClick={toolbarClick}
					actionComplete={props.actionComplete}
					sortSettings={props.sortSettings ? props.sortSettings : { columns: [{ field: "id", direction: "Descending" }] }}
					pageSettings={{ pageCount: 5 }}>
					<ColumnsDirective>
						{getColumns()}
						<ColumnDirective headerText='' width='90' template={(props) => {
							return getActionButtons(props);
						}} textAlign='Center'
							filterTemplate={() => false}
						/>
					</ColumnsDirective>
					<Inject services={getServices()} />
				</GridComponent>
			</div>
		</div>);
}
export default React.forwardRef(MyGridView);