import { Icon, Menu, MenuItem } from '@mui/material';
import { useMaterialUIController } from 'context';
import NotificationItem from 'examples/Items/NotificationItem';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotificationList = (props) => {
    let navigate = useNavigate();
    const [controller, dispatch] = useMaterialUIController();
    const {
        notifications
    } = controller;
    return (
        <Menu
            open={Boolean(props.openMenu)}
            onClose={() => props.handleCloseMenu()}
            anchorEl={props.openMenu}
            sx={{ mt: 8 }}
            anchorReference="anchorPosition"
            anchorPosition={{ top: props.openMenu ? 0 : 0, left: 0 }}
        >
            <Col style={{ height: "300px", overflowY: "scroll" }}>
                {notifications.map((e, idx) => <NotificationItem key={e.id} icon={<Icon>email</Icon>} title={e.title} />)}
            </Col>
            <Row>
                <Button onClick={() => {
                    props.handleCloseMenu();
                    navigate("/notifications")
                }}>عرض الجميع</Button>
            </Row>
        </Menu>
    );
}

export default NotificationList;
