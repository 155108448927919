import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import CatigoriesList from "layouts/Category"
// @mui icons
import Icon from "@mui/material/Icon";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryDetails from "layouts/Category/details";
import UsersList from "layouts/users";
import CustomersClassesList from "layouts/customersClasses";
import CustomerClasseDetails from "layouts/customersClasses/details";
import POList from "layouts/po";
import PODetails from "layouts/po/details";
import ItemsList from "layouts/items";
import ItemDetails from "layouts/items/details";
import UsersRouter from "components/UsersRouter";
import AgentsList from "layouts/agents";
import StoreManagersList from "layouts/storeManagers";
import CustomersList from "layouts/customers";
import UserDetails from "layouts/users/details";
import AgentDetails from "layouts/agents/details";
import StoreManagerDetails from "layouts/storeManagers/details";
import CustomerDetails from "layouts/customers/details";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SOList from "layouts/so";
import SoDetails from "layouts/so/details";
import StockItemsList from "layouts/stockItems";
import StockItemDetails from "layouts/stockItems/details";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import BalanceChargeList from "layouts/balanceCharge/BalanceChargeList";
import Transactions from "layouts/transactions";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Offers from "layouts/offers";
import OfferDetails from "layouts/offers/details";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import News from "layouts/news";
import NewsDetails from "layouts/news/details";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ExtraFields from "layouts/extraFields";
import ExtraFiledsDetails from "layouts/extraFields/details";
import BalanceRequest from "layouts/balanceRequest";
import NotificationsList from "layouts/notificationsList";
import PaymentMethods from "layouts/PaymentMethods";
import PaymentMethodDetails from "layouts/PaymentMethods/details";
import Preferences from "layouts/Preferences";
import DynamicPages from "layouts/dynamicPages";
import DynamicPageDetails from "layouts/dynamicPages/details";
import Reports from "layouts/reports";
import RptSalesByCategory from "layouts/reports/RptSalesByCategory";
import RptGetSalesTotal from "layouts/reports/RptGetSalesTotal";
import RptGetSalesByItem from "layouts/reports/RptGetSalesByItem";
import RptGetSalesByCustomer from "layouts/reports/RptGetSalesByCustomer";
import RptGetSalesByDate from "layouts/reports/RptGetSalesByDate";
import RptGetSalesByAgent from "layouts/reports/RptGetSalesByAgent";
import RptGetSalesByAgentDaily from "layouts/reports/RptGetSalesByAgentDaily";

const routes = [
  {
    type: "collapse",
    name: "لوحة التحكم",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // --------------Categries-------------------
  {
    type: "collapse",
    name: "العائلات",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categories",
    component: <CatigoriesList />,
  },
  {
    type: "hide",
    name: "تفاصيل",
    key: "details",
    icon: <Icon fontSize="small">categories</Icon>,
    route: "/categories/details",
    component: <CategoryDetails />,
  },
  // ------------customerClasses---------------------
  {
    type: "collapse",
    name: "تصنيفات العملاء",
    key: "customerClasses",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/customerClasses",
    component: <CustomersClassesList />,
  },
  {
    type: "hide",
    name: "معلومات التصنيف",
    key: "",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/customerClass/:id",
    component: <CustomerClasseDetails />,
  },
  // ------------poList---------------------
  {
    type: "collapse",
    name: "طلبات الشراء",
    key: "po",
    icon: <ShoppingCartIcon />,
    route: "/po",
    component: <POList />,
  },
  {
    type: "hide",
    name: "معلومات طلب الشراء",
    key: "",
    icon: <ShoppingCartIcon />,
    route: "/po/:id",
    component: <PODetails />,
  },
  // ------------itemsList---------------------
  {
    type: "collapse",
    name: "المنتجات",
    key: "items",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/items",
    component: <ItemsList />,
  },
  {
    type: "hide",
    name: "معلومات المنتج",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/items/:id",
    component: <ItemDetails />,
  },
  {
    type: "collapse",
    name: "قائمة الطلبات",
    key: "so",
    icon: <AttachMoneyIcon fontSize="small"></AttachMoneyIcon>,
    route: "/so",
    component: <SOList />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/so/:id",
    component: <SoDetails />,
  },
  // ------------------StockItems-----------------------
  {
    type: "collapse",
    name: "بطاقات المخزون",
    key: "stockItems",
    icon: <AttachMoneyIcon fontSize="small"></AttachMoneyIcon>,
    route: "/stockItems",
    component: <StockItemsList />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/stockItems/:id",
    component: <StockItemDetails />,
  },
  {
    type: "collapse",
    name: "حركات الشحن",
    key: "balanceCharge",
    icon: <SyncAltIcon fontSize="small"></SyncAltIcon>,
    route: "/balanceCharge",
    component: <BalanceChargeList />,
  },
  {
    type: "collapse",
    name: "العروض",
    key: "offers",
    icon: <LocalOfferIcon fontSize="small"></LocalOfferIcon>,
    route: "/offers",
    component: <Offers />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/offers/:id",
    component: <OfferDetails />,
  },
  {
    type: "collapse",
    name: "الأخبار",
    key: "news",
    icon: <NewspaperIcon fontSize="small"></NewspaperIcon>,
    route: "/news",
    component: <News />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/news/:id",
    component: <NewsDetails />,
  },
  // ------------------Customers-------------------------
  {
    type: "divider"
  },
  {
    type: "reactComponent",
    name: "الموظفين",
    key: "users",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/usersCollapsable",
    reactComponent: <UsersRouter />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/users/:id",
    component: <UserDetails />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/users",
    component: <UsersList />,
  },
  {
    type: "hide",
    name: "",
    key: "agents",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/agents",
    component: <AgentsList />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/agents/:id",
    component: <AgentDetails />,
  },
  {
    type: "hide",
    name: "",
    key: "storeManagers",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/storeManagers",
    component: <StoreManagersList />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/storeManagers/:id",
    component: <StoreManagerDetails />,
  },
  {
    type: "hide",
    name: "",
    key: "Customer",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/customers",
    component: <CustomersList />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/customers/:id",
    component: <CustomerDetails />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/transactionList/:id",
    component: <Transactions />,
  },
  {
    type: "divider"
  },
  //----------Extra Feilds
  {
    type: "collapse",
    name: "طلبات الرصيد",
    key: "balanceRequest",
    icon: <TextFieldsIcon fontSize="small"></TextFieldsIcon>,
    route: "/balanceRequest",
    component: <BalanceRequest />,
  },
  {
    type: "collapse",
    name: "الحقول الإضافية",
    key: "extraFields",
    icon: <TextFieldsIcon fontSize="small"></TextFieldsIcon>,
    route: "/extraFields",
    component: <ExtraFields />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/extraFields/:id",
    component: <ExtraFiledsDetails />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/notifications",
    component: <NotificationsList />,
  },
  {
    type: "collapse",
    name: "طرق الدفع",
    key: "paymentMethods",
    icon: <TextFieldsIcon fontSize="small"></TextFieldsIcon>,
    route: "/paymentMethods",
    component: <PaymentMethods />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/paymentMethods/:id",
    component: <PaymentMethodDetails />,
  },
  {
    type: "collapse",
    name: "التفضيلات",
    key: "preferences",
    icon: <TextFieldsIcon fontSize="small"></TextFieldsIcon>,
    route: "/preferences",
    component: <Preferences />,
  },
  {
    type: "collapse",
    name: "الصفحات الإضافية",
    key: "dynamicPages",
    icon: <TextFieldsIcon fontSize="small"></TextFieldsIcon>,
    route: "/dynamicPages",
    component: <DynamicPages />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/dynamicPages/:id",
    component: <DynamicPageDetails />,
  },
  // ----------------------Reports--------------------------------
  {
    type: "collapse",
    name: "التقارير",
    key: "reports",
    icon: <TextFieldsIcon fontSize="small"></TextFieldsIcon>,
    route: "/reports",
    component: <Reports />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByCategory",
    component: <RptSalesByCategory />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByItem",
    component: <RptGetSalesByItem />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByCustomer",
    component: <RptGetSalesByCustomer />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByDate",
    component: <RptGetSalesByDate />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByTotal",
    component: <RptGetSalesTotal />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByAgent",
    component: <RptGetSalesByAgent />,
  },
  {
    type: "hide",
    name: "",
    key: "",
    icon: null,
    route: "/reports/GetSalesByAgentDaily",
    component: <RptGetSalesByAgentDaily />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    // route: "/authentication/sign-in",
    route: "/",
    component: <SignIn />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
