import { toast } from "react-toastify";

class authService {
    login(username, password, rememberMe) {
        return fetch(`${process.env.REACT_APP_BASE_URL}/Auth/Login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "userName": username,
                "password": password,
                "rememberMe": rememberMe
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.isSuccess) {
                    // Save token to local storage
                    localStorage.setItem('JWT', res.data.tokenKey);
                } else {
                    toast.error(Object.keys(res.errors).map(key => {
                        return `${key} : ${res.errors[key][0]}`
                    }).join())
                    return false;
                }
                return res.isSuccess;
            });
    }

    logout() {
        // Remove token from local storage
        localStorage.removeItem('JWT');
    }

    getToken() {
        // Get token from local storage
        return localStorage.getItem('JWT');
    }

    validateToken(token) {
        return fetch(`${process.env.REACT_APP_BASE_URL}/Auth/TryAutoLogin`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(response => {
            return response.json().then(jsonData => {
                return jsonData.isSuccess;
            })
        }).catch(error => {
            return false;
        })
    }

    async activateAccount(id) {
        const result = await fetch(`${process.env.REACT_APP_BASE_URL}/Auth/ActivateUser?userId=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('JWT')}`
            },
        })
        const response = await result.json();
        console.log('response : ', response);
        if (response.isSuccess) {
            return true;
        } else {
            return false;
        }
    }

    async deActivateAccount(id) {
        const result = await fetch(`${process.env.REACT_APP_BASE_URL}/Auth/DeActivateUser?userId=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('JWT')}`
            },
        })
        const response = await result.json();
        console.log('response : ', response);
        if (response.isSuccess) {
            return true;
        } else {
            return false;
        }
    }

    isLoggedIn() {
        // Check if a token exists in local storage
        return !!localStorage.getItem('JWT');
    }
}

export default new authService();