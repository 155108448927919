class POModel {
    constructor (id, poNumber, supplierId, poDate, totalCost, notes, isPosted, pOdtls = []) {
        this.id = id;
        this.poNumber = poNumber;
        this.supplierId = supplierId;
        this.poDate = poDate;
        this.totalCost = totalCost;
        this.notes = notes;
        this.isPosted = isPosted;
        this.pOdtls = pOdtls;
    }

    static fromJson = (json) => {
        return new POModel(
            json.id,
            json.poNumber,
            json.supplierId,
            json.poDate,
            json.totalCost,
            json.notes,
            json.isPosted,
            json.pOdtls,
        );
    }

    toJson = () => {
        return {
            id: this.id,
            poNumber: this.poNumber,
            supplierId: this.supplierId,
            poDate: this.poDate,
            totalCost: this.totalCost,
            notes: this.notes,
            isPosted: this.isPosted,
            pOdtls: this.pOdtls,
        };
    }
}

export default POModel;
