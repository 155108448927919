import HttpService from '../utility/httpService';
class customerClassService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_Customers/GetAllCustomerClass");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_Customers/GetCustomerClassById?id=" + id);
        return response;
    }

    async create(customerClassData) {
        const response = await HttpService.post("/Admin/CMS_Customers/AddCustomerClass", customerClassData);
        return response;
    }

    async update(customerClassData) {
        const response = await HttpService.put("/Admin/CMS_Customers/EditCustomerClass", customerClassData);
        return response;
    }

    async delete(id) {
        const response = await HttpService.put("/Admin/CMS_Customers/DeleteCustomerClass?id=" + id);
        return response;
    }
}

export default new customerClassService();