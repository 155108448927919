import { Icon } from '@mui/material';
import MyGridView from 'components/GridView';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import withAuth from 'functions/withAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReportsList = () => {
    let navigate = useNavigate();
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MyGridView
                source={[
                    { id: "GetSalesByCategory", name: "تقرير المبيعات حسب العائلة" },
                    { id: "GetSalesByItem", name: "تقرير المبيعات حسب المنتح" },
                    { id: "GetSalesByCustomer", name: "تقرير المبيعات حسب العميل" },
                    { id: "GetSalesByDate", name: "تقرير المبيعات حسب التاريخ" },
                    { id: "GetSalesByTotal", name: "تقرير المبيعات تجميعي" },
                    { id: "GetSalesByAgent", name: "تقرير المبيعات حسب الوكيل" },
                    { id: "GetSalesByAgentDaily", name: "تقرير المبيعات حسب الوكيل يوميا" },
                ]}
                columns={[
                    {
                        "field": "id",
                        "headerText": "#",
                        "width": 70,
                        "textAlign": "right"
                    },
                    {
                        "field": "name",
                        "headerText": "الاسم",
                        "width": 90,
                        "textAlign": "right"
                    },
                ]}
                allowFiltering={true}
                actionButtons={[
                    {
                        "content": <Icon color='info'>visibility</Icon>,
                        "action": (id) => navigate("/reports/" + id)
                    },
                ]}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default withAuth(ReportsList);
