import { CircularProgress, Switch } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import storeManagerService from 'services/storeManagerService';
import SearchList from 'components/SearchList';
import withAuth from 'functions/withAuth';
import ManageBalance from 'components/ManageBalance';
import auth from 'services/auth';
import { Col, Form, Row } from 'react-bootstrap';

const StoreManagerDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [countries, setCountries] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryId, setCountryId] = useState(1);
    const [cityId, setCityId] = useState(1);
    const [storeName, setStoreName] = useState("");
    const [storeAddress, setStoreAddress] = useState("");
    const [storeLocation, setStoreLocation] = useState("");
    const [currentBalance, setCurrentBalance] = useState(0);
    const [isExemptOfMaxSalesQty, setIsExemptOfMaxSalesQty] = useState(false);
    const [isExemptOfMaxSalesQtyInDay, setIsExemptOfMaxSalesQtyInDay] = useState(false);
    const [maxEmployeesCount, setMaxEmployeesCount] = useState(0);
    const [storeImage, setStoreImage] = useState("");

    const isEditMode = Number(id);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const coutriesData = await storeManagerService.getAllCountries();
                const citiesData = await storeManagerService.getAllCities();
                setCountries([...coutriesData]);
                setAllCities([...citiesData]);
                setCities(citiesData.filter(x => x.countryId === 1).map(x => { return { id: x.id, name: x.nameAr } }));

                if (isEditMode) {
                    const res = await storeManagerService.getById(id);
                    if (isMounted) {
                        setCities(citiesData.filter(x => x.countryId === res.countryId).map(x => { return { id: x.id, name: x.nameAr } }));
                        setFirstName(res.firstName);
                        setLastName(res.lastName);
                        setEmail(res.email);
                        setPassword(res.password);
                        setPhoneNumber(res.phoneNumber);
                        setIsActive(res.isActive);
                        setCurrentBalance(res.currentBalance);
                        setStoreName(res.storeName);
                        setStoreAddress(res.storeAddress);
                        setStoreLocation(res.storeLocation);
                        setIsExemptOfMaxSalesQty(res.isExemptOfMaxSalesQty);
                        setIsExemptOfMaxSalesQtyInDay(res.isExemptOfMaxSalesQtyInDay);
                        setStoreImage(res.storeImage);
                        setCountryId(res.countryId);
                        setCityId(res.cityId);
                    }
                    setIsDataLoaded(true);
                } else {
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        if (isEditMode) {
        } else {
            setIsDataLoaded(true);
        }

        return () => {
            // Cleanup function
            isMounted = false;
            // Cancel any ongoing asynchronous tasks or subscriptions here
            // ...
        };
    }, [id, isEditMode]);

    const validatePassword = (password) => {
        // Minimum length of 8 characters
        if (password.length < 8) {
            return 'Password must be at least 8 characters long.';
        }

        // At least 3 letters
        const letterRegex = /[a-zA-Z]/g;
        const letterMatches = password.match(letterRegex);
        if (!letterMatches || letterMatches.length < 3) {
            return 'Password must contain at least 3 letters.';
        }

        // At least 1 uppercase letter
        const uppercaseRegex = /[A-Z]/g;
        const uppercaseMatches = password.match(uppercaseRegex);
        if (!uppercaseMatches || uppercaseMatches.length < 1) {
            return 'Password must contain at least 1 uppercase letter.';
        }

        // At least 3 numeric characters
        const numericRegex = /[0-9]/g;
        const numericMatches = password.match(numericRegex);
        if (!numericMatches || numericMatches.length < 3) {
            return 'Password must contain at least 3 numeric characters.';
        }

        // At least 1 special character
        const specialRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
        const specialMatches = password.match(specialRegex);
        if (!specialMatches || specialMatches.length < 1) {
            return 'Password must contain at least 1 special character.';
        }

        // Password meets all criteria
        return null;
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPasswordError(validatePassword(newPassword));
    };

    const handleSubmit = () => {
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        setWaiting(true);

        const storeManagerData = {
            "countryId": countryId,
            "cityId": cityId,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phoneNumber": phoneNumber,
            "password": password,
            "storeName": storeName,
            "storeAddress": storeAddress,
            "storeLocation": storeLocation,
            "isActive": isActive,
            "isChangedPassword": false,
            "isExemptOfMaxSalesQty": isExemptOfMaxSalesQty,
            "isExemptOfMaxSalesQtyInDay": isExemptOfMaxSalesQtyInDay,
            "maxEmployeesCount": maxEmployeesCount,

        };

        if (isEditMode) {
            storeManagerData.id = id;
            storeManagerService.update(storeManagerData).then(() => {
                setWaiting(false);
            });
        } else {
            storeManagerData.password = password;
            storeManagerService.create(storeManagerData).then(res => {
                if (res) {
                    navigate('/storeManagers');
                }
                setWaiting(false);
            });
        }
    };
    function activateAccount() {
        if (auth.activateAccount(id)) {
            setIsActive(true);
            toast.success("تم التفعيل بنجاح");
        } else {
            toast.error("حطأ في عملية تفعيل الحساب");
        }
    }
    function deActivateAccount() {
        if (auth.deActivateAccount(id)) {
            setIsActive(false);
            toast.success("تم إلغاء التفعيل بنجاح");
        } else {
            toast.error("حطأ في عملية إلغاء تفعيل الحساب");
        }
    }

    if (!isDataLoaded) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <CircularProgress color="success" size={60} />
                <p style={{ color: 'green' }}>الرجاء الإنتظار</p>
            </DashboardLayout>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                isEditMode
                    ?
                    <ManageBalance
                        name={firstName + " " + lastName}
                        userId={id}
                    />
                    :
                    <></>
            }
            {
                isEditMode
                    ?
                    <Row>
                        <Col md={3}>
                            <p className="control-label" style={{ textAlign: 'left', fontSize: '16px', fontWeight: 'bold', color: currentBalance < 0 ? 'red' : 'green' }}>
                                الرصيد الحالي : {currentBalance}</p>
                        </Col>
                    </Row>
                    :
                    <></>
            }
            <Row>

                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        الاسم الأول</Form.Label>
                    <Form.Control value={firstName} onChange={e => setFirstName(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        الاسم الأول</Form.Label>
                    <Form.Control value={firstName} onChange={e => setFirstName(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        الاسم الثاني</Form.Label>
                    <Form.Control value={lastName} onChange={e => setLastName(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        الدولة</Form.Label>
                    <SearchList
                        data={countries}
                        take={10}
                        value={countryId}
                        onChange={(id, name) => {
                            setCountryId(id)
                            let countryCities = allCities.filter(x => x.countryId === id)
                            setCities([]);
                            setCities(countryCities.map(x => { return { id: x.id, name: x.nameAr } }));
                            setCityId(countryCities[0].id)
                        }}
                    />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        المدينة</Form.Label>
                    <SearchList
                        data={cities}
                        take={10}
                        value={cityId}
                        onChange={(id, name) => setCityId(id)}
                    />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        البريد الإلكتروني</Form.Label>
                    <Form.Control value={email} onChange={e => setEmail(e.target.value)} />
                </Col>
                {!isEditMode && (
                    <Col md={3}>
                        <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            كلمة المرور</Form.Label>
                        <Form.Control value={password} onChange={e => handlePasswordChange(e.target.value)} />
                    </Col>
                )}
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        رقم الهاتف</Form.Label>
                    <Form.Control value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        اكثر حد للموظفين</Form.Label>
                    <Form.Control type="number" value={maxEmployeesCount} onChange={e => setMaxEmployeesCount(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        اسم المتجر</Form.Label>
                    <Form.Control value={storeName} onChange={e => setStoreName(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        عنوان المتجر</Form.Label>
                    <Form.Control value={storeAddress} onChange={e => setStoreAddress(e.target.value)} />
                </Col>
                <Col md={3}>
                    <Form.Label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                        موقع المتجر</Form.Label>
                    <Form.Control value={storeLocation} onChange={e => setStoreLocation(e.target.value)} />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md={2}>
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={() => { }}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            معفى من حد المنتجات </label>
                    </MDTypography>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch checked={isExemptOfMaxSalesQty} onChange={() => setIsExemptOfMaxSalesQty(!isExemptOfMaxSalesQty)} />
                    </MDBox>
                </Col>
                <Col md={2}>
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={() => { }}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            معفى من الحد اليومي</label>
                    </MDTypography>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch checked={isExemptOfMaxSalesQtyInDay} onChange={() => setIsExemptOfMaxSalesQtyInDay(!isExemptOfMaxSalesQtyInDay)} />
                    </MDBox>
                </Col>
                <Col md={2}>
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={() => { }}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                        <label className="control-label" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                            تفعيل الحساب </label>
                    </MDTypography>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch checked={isActive} disabled={true} onChange={() => setIsActive(!isActive)} />
                    </MDBox>
                    {
                        isActive
                            ?
                            <MDButton variant="gradient" color="error" fullWidth onClick={deActivateAccount}>
                                إلغاء تفعيل الحساب
                            </MDButton>
                            :
                            <MDButton variant="gradient" color="success" fullWidth onClick={activateAccount}>
                                تفعيل الحساب
                            </MDButton>

                    }
                </Col>
                {storeImage
                    ?
                    <Col>
                        <div style={{ width: "150px", padding: "5px" }}>
                            <img style={{ width: "100px" }} src={storeImage} alt="Nothing to present" />
                        </div>
                    </Col>
                    :
                    <></>
                }
            </Row>
            <div style={{ display: "flex" }}>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    {waiting ? (
                        <CircularProgress color="success" />
                    ) : (
                        <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                            حفظ
                        </MDButton>
                    )}
                </MDBox>
                <MDBox display="flex" alignItems="center" width="200px" margin="10px">
                    <MDButton variant="gradient" color="primary" fullWidth onClick={() => navigate('/storeManagers')}>
                        الرجوع إلى القائمة
                        <ArrowBackIcon style={{ margin: "0 10px" }} />
                    </MDButton>
                </MDBox>
            </div>
        </DashboardLayout>
    );
};

export default withAuth(StoreManagerDetails);
