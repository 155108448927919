import HttpService from '../utility/httpService';
class paymentMethodService {
    async getAll() {
        const response = await HttpService.get("/Admin/CMS_PaymentMethod/GelAll");
        return response;
    }

    async getById(id) {
        const response = await HttpService.get("/Admin/CMS_PaymentMethod/GetById?id=" + id);
        return response;
    }

    async create(data) {
        const response = await HttpService.post("/Admin/CMS_PaymentMethod/Add", data);
        return response;
    }

    async update(data) {
        const response = await HttpService.put("/Admin/CMS_PaymentMethod/Edit", data);
        return response;
    }

    async delete(id) {
        const response = await HttpService.delete("/Admin/CMS_PaymentMethod/Delete?id=" + id);
        return response;
    }
}

export default new paymentMethodService();