import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import React from 'react';
import Link from "@mui/material/Link";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import { Icon } from '@mui/material';

const UsersRouter = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const collapseName = location.pathname.replace("/", "");
    return (
        <div>
            <ul>
                <p style={{
                    color: "white",
                    margin: "10px 40px",
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "0.99rem",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                }} onClick={() => { setCollapsed(!collapsed) }}>المستخدمين
                    {collapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</p>
                {collapsed ? null : <>
                    <div
                        onClick={() => { navigate("/users") }}
                        key={"users"}
                    >
                        <SidenavCollapse name={"الموظفين"} icon={<Icon fontSize="small">people</Icon>} active={collapseName === "users"} />
                    </div>
                    <div
                        onClick={() => { navigate("/agents") }}
                        key={"agents"}
                    >
                        <SidenavCollapse name={"الوكلاء"} icon={<Icon fontSize="small">people</Icon>} active={collapseName === "agents"} />
                    </div>
                    <div
                        onClick={() => { navigate("/storeManagers") }}
                        key={"storeManagers"}
                    >
                        <SidenavCollapse name={"التجار"} icon={<Icon fontSize="small">people</Icon>} active={collapseName === "managers"} />
                    </div>
                    <div
                        onClick={() => { navigate("/customers") }}
                        key={"customers"}
                    >
                        <SidenavCollapse name={"الزبائن"} icon={<Icon fontSize="small">people</Icon>} active={collapseName === "customers"} />
                    </div>
                </>}
            </ul>
        </div>
    );
}

export default UsersRouter;
